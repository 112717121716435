import { ArtworkField } from './ArtworkField';
import { getDescriptionGl, getTitle } from '../../utils/contentHelpers';

export const ArtworkCatRais = ({ artwork }) => {
  const title = artwork?.title ? getTitle(artwork) : getDescriptionGl(artwork);
  const fontTitle = artwork?.title ? '18px' : '13px';
  return (
    <div style={{ fontSize: '13px' }}>
      <div className="mt-3" style={{ fontSize: '24px' }}>
        {' '}
        {artwork?.artist}{' '}
      </div>

      <div className="mt-3">
        <div style={{ fontSize: '18px', fontFamily: 'ArtWishListMedium' }}>
          <ArtworkField field={artwork?.catalogue_number} label="" />
        </div>
      </div>

      <div
        className="mt-3 text-justify"
        style={{ fontSize: fontTitle, whiteSpace: 'pre-line' }}
      >
        {title}
      </div>

      <div className="mt-3">{artwork?.materials}</div>
      <div>{artwork?.size}</div>
      <div>{artwork?.duration}</div>

      <div className="mt-3 font-weight-bold">
        <div style={{ fontSize: '18px' }}>{artwork?.sales_prices}</div>

        <div>{artwork?.sales_estimate}</div>
        <div>{artwork?.sales_house}</div>
        <div>{artwork?.date}</div>
      </div>

      <ArtworkField field={artwork?.collection} label="" />

      <div className="mt-3 text-justify">
        <ArtworkField field={artwork?.location} label="Location" />
        <ArtworkField field={artwork?.acquisition} label="Acquisition Method" />
        <ArtworkField
          field={artwork?.inventory_number}
          label="Inventory Number"
        />
        <ArtworkField field={artwork?.description} label="Description" />
        <ArtworkField field={artwork?.inscriptions} label="Incriptions" />
        <ArtworkField field={artwork?.misc} label="Miscellaneous" />
        <ArtworkField
          field={artwork?.year_of_casting}
          label="Year Of Casting"
        />
        <ArtworkField field={artwork?.foundry} label="Foundry" />
        <ArtworkField field={artwork?.edition} label="Edition" />
        <ArtworkField field={artwork?.printer} label="Printer" />
        <ArtworkField field={artwork?.publisher} label="Publisher" />
        <ArtworkField field={artwork?.rights} label="Rights" />

        <ArtworkField field={artwork?.provenance} label="Provenance" />
        <ArtworkField
          field={artwork?.exhibition_history}
          label="Exhibition History"
        />
        <ArtworkField field={artwork?.literature} label="Literature" />
      </div>

      <ArtworkField field={artwork?.source_name} label="Source" />

      {artwork?.url && artwork?.url.includes('publitas.com') && (
        <div style={{ fontSize: '12px' }}>
          <div className="mt-3" style={{ fontWeight: 'bold' }}>
            URL
          </div>
          <a
            href={artwork?.url}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          >
            {artwork?.url}
          </a>
        </div>
      )}
    </div>
  );
};
