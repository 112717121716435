import styled from 'styled-components';
import { ArtworkAuction } from './ArtworkAuction';
import { ArtworkCatRais } from './ArtworkCatRais';
import { ArtworkDefault } from './ArtworkDefault';
import { ArtworkOnline } from './ArtworkOnline';
import { ArtworkPrivateCollection } from './ArtworkPrivateCollection';
import { ARTWORK_TYPE, getSource, getSourceStr } from '../../shared/utils';

const Source = styled.div`
  font-family: ArtWishListMedium;
  font-size: 14px;
`;

export const ArtworkData = ({ artwork }) => {
  const source = getSource(artwork?.source_type);
  const sourceCategory = artwork?.source_category;
  const sourceStr = getSourceStr(+sourceCategory);

  // return variant;
  const props = { artwork };
  const auction = source === ARTWORK_TYPE.AUCTION;
  const catalogueRaisonne = source === ARTWORK_TYPE.CATALOGUE_RAISSONE;
  const online = source === ARTWORK_TYPE.ONLINE;
  const privateCollection = source === ARTWORK_TYPE.PRIVATE_COLLECTION;

  return (
    <>
      <Source>{sourceStr !== 'UNKNOWN' && sourceStr}</Source>
      {catalogueRaisonne && <ArtworkCatRais {...props} />}
      {privateCollection && <ArtworkPrivateCollection {...props} />}
      {online && <ArtworkOnline {...props} />}
      {auction && <ArtworkAuction {...props} />}
      {!auction && !catalogueRaisonne && !online && !privateCollection && (
        <ArtworkDefault {...props} />
      )}
    </>
  );
};
