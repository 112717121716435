import React from 'react';
import styled, { css } from 'styled-components';

import LazyImage from './LazyImage';
import {
  getSourceStr,
  getSource,
  ARTWORK_TYPE,
  getEstimate,
  getEstimateCurrency,
  getPrice,
} from '../shared/utils';
import { useAppState } from '../hooks/useAppContext';
import useImageFetch from '../hooks/useImageFetch';

const CardContainer = styled.div`
  flex: 0 0 calc(25% - 1rem);
  font-size: 14px;
  margin: 0.5rem;

  ${(props) =>
    props.isTablet &&
    css`
      flex: 0 0 calc(33% - 1rem);
    `}

  ${(props) =>
    props.isMobile &&
    css`
      flex: 0 0 calc(50% - 1rem);
    `}

  ${(props) =>
    props.isSmallMobile &&
    css`
      flex: 0 0 100%;
      margin: 0 0 1rem;
      max-width: 100%;
    `}
`;

const TextWrapper = styled.div`
  padding: 1rem 0;
`;

const Category = styled.div`
  border-radius: 5px 5px 0 0;
  color: #000;
  display: -webkit-box;
  font-size: 14px;
  font-weight: bold;
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const CategoryWrapper = styled.div`
  padding: 0.5rem 0;
`;

// const ImageLink = styled(NavLink)`
const ImageLink = styled.div`
  color: #444444;
  &:hover {
    color: #444444;
    text-decoration: none;
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  background: #ededed;
  border-bottom: 1px solid #aaaaaa;
  border-top: 1px solid #aaaaaa;
  cursor: pointer;
  padding: 1em 20%;
  display: flex;
  height: 200px;
  justify-content: center;
  width: 100%;

  &:hover {
    background: #fff;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const CardField = styled.div`
  font-family: ${(props) => (props.bold ? 'ArtWishListMedium' : 'inherit')};

  // display: -webkit-box;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: ${(props) => (props.isSmallMobile ? '100%' : '200px')};
`;

const getSize = (props) => {
  const unit =
    props.artwork.size_unit === 'in' ? 'inches' : props.artwork.size_unit;
  if (props.artwork.size_height && props.artwork.size_width) {
    return `${props.artwork.size_height} x ${props.artwork.size_width} ${unit}`;
  } else if (props.artwork.size_height) {
    return `${props.artwork.size_height} ${unit}`;
  } else if (props.artwork.size_width) {
    return `${props.artwork.size_width} ${unit}`;
  }
  return '';
};

const getTitle = (props) => {
  if (props.artwork.title && props.artwork.year_of_work_a) {
    return `${props.artwork.title}, ${props.artwork.year_of_work_a}`;
  } else if (props.artwork.title) {
    return `${props.artwork.title}`;
  } else if (props.artwork.year_of_work_a) {
    return `${props.artwork.year_of_work_a}`;
  }
  return '';
};

const CardDataCatRais = (props) => {
  const { media } = useAppState();
  let description_gl = '';
  if (props.artwork.description_gl)
    description_gl = props.artwork.description_gl
      .split('\n')
      .slice(0, 4)
      .join('\n');

  let title = props.artwork.title ? getTitle(props) : description_gl;

  let catalogue_number = props.artwork.catalogue_number;
  if (catalogue_number) {
    if (catalogue_number.startsWith('v')) {
      catalogue_number =
        'Volume ' +
        catalogue_number.split('_')[0].slice(1) +
        ', No. ' +
        catalogue_number.split('_')[1];
    } else {
      catalogue_number = 'No. ' + catalogue_number;
    }
  }

  return (
    <div style={{ fontSize: '15px', lineHeight: '1.2' }}>
      <CardField {...media} bold>
        {props.artwork.artist}
      </CardField>
      <CardField {...media}>{title}</CardField>
      <CardField {...media}>{getSize(props)}</CardField>
      <CardField {...media}>{props.artwork.materials}</CardField>
      <CardField {...media}>{props.artwork.collection}</CardField>
      <CardField {...media} bold className="mt-3">
        {catalogue_number}
      </CardField>
      <CardField {...media}>{props.artwork.source_name}</CardField>
    </div>
  );
};

const CardDataAuction = (props) => {
  const { media } = useAppState();
  // Compute price
  let date = null;
  if (props.artwork.date) {
    const dateObj = new Date(props.artwork.date);
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    date = `${dateObj.getDate()}  ${
      monthNames[dateObj.getMonth()]
    } ${dateObj.getFullYear()}`;
  }

  // Compute estimate
  getEstimate(props.artwork.estimate);
  const sales_estimate_currency = getEstimateCurrency(props.artwork.estimate);

  // Compute sales
  const sales_prices = getPrice(
    props.artwork.sales_prices,
    sales_estimate_currency,
  );

  // Calculate price
  let price = sales_prices ? sales_prices : 'No price information';

  // Add description_gl truncated at line4
  return (
    <div style={{ fontSize: '15px', lineHeight: '1.2' }}>
      <CardField {...media} bold>
        {props.artwork.artist}
      </CardField>
      <CardField {...media}>{getTitle(props)}</CardField>
      <CardField {...media}>{getSize(props)}</CardField>
      <CardField {...media}>{props.artwork.materials}</CardField>
      <CardField {...media}>{props.artwork.collection}</CardField>
      <div className="mt-3">
        <CardField {...media} bold>
          {props.artwork.catalogue_number
            ? `No. ${props.artwork.catalogue_number}`
            : ''}
        </CardField>
        <CardField {...media} bold>
          {props.artwork.bought_in ? 'Bought in' : price}
        </CardField>
        <CardField {...media}>{props.artwork.house}</CardField>
        <CardField {...media}>{date}</CardField>
      </div>
    </div>
  );
};

const CardDataOnline = (props) => {
  const { media } = useAppState();
  return (
    <div style={{ fontSize: '15px', lineHeight: '1.2' }}>
      <CardField {...media} bold>
        {props.artwork.artist}
      </CardField>
      <CardField {...media}>{getTitle(props)}</CardField>
      <CardField {...media}>{getSize(props)}</CardField>
      <CardField {...media}>{props.artwork.materials}</CardField>
      <div className="mt-3">
        <CardField {...media} bold>
          {props.artwork.collection}
        </CardField>
        <CardField {...media}>{props.artwork.location}</CardField>
        <CardField {...media}>{props.artwork.house}</CardField>
      </div>
    </div>
  );
};

const CardDataMuseum = (props) => {
  const { media } = useAppState();
  return (
    <div
      className="text-truncate"
      style={{ fontSize: '15px', lineHeight: '1.2' }}
    >
      <CardField {...media} bold>
        {props.artwork.artist}
      </CardField>
      <CardField {...media}>{getTitle(props)}</CardField>
      <CardField {...media}>{getSize(props)}</CardField>
      <CardField {...media}>{props.artwork.materials}</CardField>
      <div className="mt-3">
        <CardField {...media} bold>
          {props.artwork.catalogue_number
            ? `No. ${props.artwork.catalogue_number}`
            : ''}
        </CardField>
        <CardField {...media}>{props.artwork.collection}</CardField>
        <CardField {...media}>{props.artwork.location}</CardField>
        <CardField {...media}>{props.artwork.house}</CardField>
      </div>
    </div>
  );
};

const CardData = (props) => {
  return (
    <>
      <div>
        <b style={{ fontSize: '15px ' }}>{props.artwork.artist}</b>
      </div>
      <div>
        <div style={{ fontSize: '15px ' }}>
          {props.artwork.title}, {props.artwork.year_of_work_a}
        </div>
        <div>{getSize(props)}</div>
      </div>
      <div>
        <div>{props.artwork.materials}</div>
        <div>{props.artwork.collection}</div>
      </div>
      <div>
        <b>
          {props.artwork.catalogue_number
            ? `No. ${props.artwork.catalogue_number}`
            : ''}
        </b>
        <div>{props.artwork.sales_prices}</div>
      </div>
    </>
  );
};

const Card = ({ onCardClick, result }) => {
  const { media } = useAppState();

  // Get source
  const source = getSource(result?.source_type);
  const sourceCategory = result?.source_category;
  const sourceStr = getSourceStr(+sourceCategory);

  let artworkData = '';
  switch (source) {
    case ARTWORK_TYPE.CATALOGUE_RAISSONE:
      artworkData = <CardDataCatRais artwork={result} />;
      break;
    case ARTWORK_TYPE.MUSEUM:
    case ARTWORK_TYPE.PRIVATE_COLLECTION:
      artworkData = <CardDataMuseum artwork={result} />;
      break;
    case ARTWORK_TYPE.ONLINE:
      artworkData = <CardDataOnline artwork={result} />;
      break;
    case ARTWORK_TYPE.AUCTION:
      artworkData = <CardDataAuction artwork={result} />;
      break;
    default:
      artworkData = <CardData artwork={result} />;
      break;
  }

  const { image } = useImageFetch(result);

  return (
    <CardContainer key={result?.pk} id={result?.pk} {...media}>
      <CategoryWrapper>
        <Category>{sourceStr}</Category>
      </CategoryWrapper>
      {/* <ImageLink to={result?.pk && `/artwork/${result.pk}`}> */}
      <ImageLink
        onClick={(e) => onCardClick && result?.pk && onCardClick(e, result.pk)}
      >
        <ImageWrapper>
          <LazyImage src={image} alt="" />
        </ImageWrapper>
      </ImageLink>
      <TextWrapper>{artworkData}</TextWrapper>
    </CardContainer>
  );
};

export default Card;
