import React from 'react';
import styled, { css } from 'styled-components';

import SvgSearch from './SvgSearch';
import { useAppState } from '../hooks/useAppContext';
import {
  searchTypeButton,
  searchTypePlaceholder,
  searchTypes,
} from '../constants/search';

const Container = styled.div`
  position: relative;
  width: ${(props) => (props.isMobile ? '100%' : '400px')};
`;

const InputField = styled.div`
  background: #f3f3f3;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #a1a1a1;
  display: flex;
  max-width: ${(props) => (props.fullWidth ? '100%' : '400px')};
  min-height: 40px;
  padding-left: 0.5rem;
  width: 100%;

  &:focus-within {
    border-color: #d0d0d0;
    background: #fff;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  max-width: 400px;
  min-height: 38px;
  outline: none;
  padding-left: 0.5rem;
  width: 100%;
`;

const Dropdown = styled.div`
  background: #fff;
  border-radius: 20px;
  border: 1px solid #d0d0d0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-top: -22px;
  max-height: 260px;
  max-width: 100%;
  min-height: 38px;
  outline: none;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
`;

const DropdownItem = styled.div`
  color: #808080;
  padding: 0.6rem 1rem;
  &:hover {
    background: #f3f3f3;
  }
  ${(props) => props.info && DropdownItemInfo};
`;

const DropdownItemInfo = css`
  text-align: center;
  &:hover {
    background: transparent;
  }
`;

const SearchTypeLink = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const SearchBar = (props) => {
  const {
    dropdownRef,
    handleSearchInputChange,
    handleSearchInputSelect,
    handleSearchSelect,
    media,
    searchInputRef,
    searchInputResults,
    searchInputResultsState,
    searchInputVisible,
    handleSearchInputFocus,
    setSearchByType,
    searchQuery,
    searchType,
    setSearchState,
    searchState,
    submitSearchForm,
  } = useAppState();

  return (
    <>
      <Container ref={dropdownRef} {...media}>
        <InputField {...props}>
          <SvgSearch width={36} />
          <Form
            onSubmit={(e) => submitSearchForm(e, setSearchState, searchState)}
          >
            <Input
              placeholder={
                searchType === searchTypes.COLLECTION
                  ? searchTypePlaceholder.COLLECTION
                  : searchTypePlaceholder.ARTIST
              }
              onFocus={handleSearchInputFocus}
              onChange={handleSearchInputChange}
              ref={searchInputRef}
              value={searchQuery}
            />
          </Form>
        </InputField>
        <SearchTypeLink>
          {searchType === searchTypes.ARTIST && (
            <span onClick={() => setSearchByType(searchTypes.COLLECTION)}>
              {searchTypeButton.COLLECTION}
            </span>
          )}
          {searchType === searchTypes.COLLECTION && (
            <span onClick={() => setSearchByType(searchTypes.ARTIST)}>
              {searchTypeButton.ARTIST}
            </span>
          )}
        </SearchTypeLink>
        {searchInputVisible && (
          <Dropdown>
            <>
              {searchInputResultsState?.loading && (
                <DropdownItem info>Loading...</DropdownItem>
              )}
              {!searchInputResultsState?.loading &&
                searchInputResults?.map((result) => (
                  <DropdownItem
                    key={result.pk}
                    onClick={() =>
                      handleSearchSelect(result, handleSearchInputSelect)
                    }
                  >
                    {result.name}
                  </DropdownItem>
                ))}

              {!searchInputResultsState?.loading &&
                !searchInputResults?.length && (
                  <DropdownItem info>No options</DropdownItem>
                )}
            </>
          </Dropdown>
        )}
      </Container>
    </>
  );
};

export default SearchBar;
