import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const LinkButton = styled(NavLink)`
  align-items: center;
  background: #f3f3f3;
  border-radius: 20px;
  color: #212529;
  display: flex;
  justify-content: center;
  font-weight: bold;
  height: 40px;
  padding: 0 1rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background: #f8ee23;
    color: #212529;
    text-decoration: none;
  }
`;

export default LinkButton;
