import React from 'react';
import styled from 'styled-components';

import { stripeRedirectToCheckout } from '../shared/utils';

const Container = styled.div`
  margin: 0 auto;
  padding: 1rem;
  max-width: 800px;
  width: 100%;
`;

const Inner = styled.div`
  align-items: center;
  background: #f8ee23;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  padding: 2rem;
  text-align: center;
`;

const Link = styled.a`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const Button = styled.button`
  background: transparent;
  border: 1px solid black;
  border-radius: 30px;
  color: #000;
  padding: 1rem 2rem;
  font-weight: bold;

  &:hover {
    background: black;
    color: #f8ee23;
  }
`;

const Spacer = styled.div`
  margin-top: 1rem;
`;

const Anonymous = ({ isLoggedIn }) => {
  return isLoggedIn ? (
    <Container>
      <Inner>
        <h3>Continue to add payment method</h3>
        <Spacer>
          First 14 days free of charge. Thereafter at $59 per month. T&C apply
        </Spacer>
        <Spacer>
          <Button onClick={stripeRedirectToCheckout}>Add payment method</Button>
        </Spacer>
      </Inner>
    </Container>
  ) : (
    <Container>
      <Inner>
        <h3>Start your free 14 day trial now to unlock this page</h3>
        <Spacer>
          Start free for 14 days free of charge
          <br />
          $59 per month thereafter. Cancel anytime. T&C apply
        </Spacer>
        <Spacer>
          <Button
            onClick={(e) =>
              (window.location = 'https://api.facture.art/login?signup=yes')
            }
          >
            Start free trial
          </Button>
        </Spacer>
        <Spacer>
          Already have an account?{' '}
          <Link href="https://api.facture.art/login">Log in</Link>
        </Spacer>
      </Inner>
    </Container>
  );
};

export default Anonymous;
