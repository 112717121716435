import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import api from '../utils/api';

function useProfile(fetchOnInit) {
  const initialProfile = { name: 'Anonymous', paid: false };
  const initialState = { data: null, error: null, loading: null };
  const [profile, setProfile] = useState(initialProfile);
  const [profileState, setState] = useState(initialState);

  const location = useLocation();
  const navigate = useNavigate();

  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    if (fetchOnInit) {
      fetchProfile();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const fetchProfile = async () => {
    setState({ ...initialState, loading: true });
    let data = { email: null, name: 'Anonymous', paid: false };
    try {
      const response = await api('https://api.facture.art/login/profile', {
        signal,
      });
      const email = response?.email || initialProfile.email;
      const name = response?.name || initialProfile.name;
      const paid = response?.paid || false;
      data = { email, name, paid };
      setState({ ...profileState, data });
      setProfile(data);
    } catch (error) {
      setState({ ...initialState, error });
      setProfile(data);
      console.log(error);
    } finally {
      setState({ ...profileState, loading: false });

      // Redirect if user has not paid
      if (!data.paid && location.path !== '/') {
        navigate('/');
      }
    }
  };

  const logout = async () => {
    const clientId = 'HUxLWjztjdAbb3xvBDCJPx4PBbiHAJsJ';
    const redirectUrl = 'https://facture.art/';
    const logoutUrl = `https://facture-1.eu.auth0.com/v2/logout?client_id=${clientId}&returnTo=${redirectUrl}`;
    const cookies = new Cookies();
    cookies.remove('token');
    localStorage.removeItem('token');
    window.location.href = logoutUrl;
    setState({ ...initialProfile });
  };

  const isLoggedIn = profile.name !== 'Anonymous';
  const isPaid = profile.paid;

  return {
    isLoggedIn,
    isPaid,
    profile,
    profileState,
    logout,
    fetchProfile,
  };
}

export default useProfile;
