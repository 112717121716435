import React, { useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Slider = styled.div`
  position: relative;
  width: 340px;
`;

const SliderTrack = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background-color: #000;
  width: 100%;
  z-index: 1;
`;

const SliderRange = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background-color: #000;
  z-index: 2;
`;

const Thumb = styled.input`
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 340px;
  outline: none;

  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #adadad;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 1px;
    pointer-events: all;
    position: relative;
  }

  &::-moz-range-thumb {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #adadad;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 1px;
    pointer-events: all;
    position: relative;
  }

  ${(props) =>
    props.left &&
    css`
      z-index: 3;
    `}

  ${(props) =>
    props.right &&
    css`
      z-index: 4;
    `}
`;

const RangeSlider = ({
  min,
  max,
  minValue,
  maxValue,
  onChangeMin,
  onChangeMax,
}) => {
  const range = useRef(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  useEffect(() => {
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValue);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minValue, maxValue, getPercent]);

  return (
    <Container>
      <Thumb
        left
        type="range"
        min={min}
        max={max}
        value={minValue}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxValue - 1);
          onChangeMin(value);
        }}
        style={{ zIndex: minValue > max - 100 && '5' }}
      />
      <Thumb
        right
        type="range"
        min={min}
        max={max}
        value={maxValue}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minValue + 1);
          onChangeMax(value);
        }}
      />

      <Slider>
        <SliderTrack />
        <SliderRange ref={range} />
      </Slider>
    </Container>
  );
};

export default RangeSlider;
