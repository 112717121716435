import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/api';

const Profile = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      await getToken();
      navigate('/search');
    };
    fetchToken();
  }, [navigate]);

  return null;
};

export default Profile;
