import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

function useMenu() {
  const menu = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  useEffect(() => {
    setMenuOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      setMenuOpen(false);
    }
  };

  return {
    menu,
    menuOpen,
    setMenuOpen,
    handleMenuOpen,
  };
}

export default useMenu;
