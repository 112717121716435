import React from 'react';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const disabledStyles = css`
  background: #f7f7f7;
  color: #d5d5d5;
  cursor: not-allowed;
`;

const hoverStyles = css`
  &:hover {
    background: #f8ee23;
  }
`;

const Button = styled.button`
  align-items: center;
  background: #f3f3f3;
  border: none;
  border-radius: 20px;
  box-shadow: none;
  color: #000;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: center;
  outline: 0;
  padding: 0.5rem 2rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${(props) => !props.disabled && hoverStyles}
  ${(props) => props.disabled && disabledStyles}
`;

const ButtonComponent = ({ children, loading, ...props }) => {
  const buttonProps = { ...props };
  delete buttonProps.loading;
  return (
    <Button {...buttonProps}>
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '24px' }} />
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonComponent;
