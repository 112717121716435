import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
  text-align: center;
  width: 100%;
  ${(props) => props.small && LoaderSmall}
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 3rem;
`;

const LoaderSmall = css`
  min-width: 74px;
  height: 24px;
  width: 24px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

const Loader = (props) => (
  <Container {...props}>
    <Icon icon={faSpinner} spin />
  </Container>
);

export default Loader;
