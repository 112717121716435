import styled from 'styled-components';

const Close = styled.div`
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;

  &:before,
  &:after {
    background: #000;
    border-radius: 4px;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: transform 0.2s ease;
    width: 100%;
  }

  &:before {
    top: 0;
    transform: translate3d(0, 9px, 0) rotate(45deg);
  }

  &:after {
    bottom: 0;
    transform: translate3d(0, -9px, 0) rotate(-45deg);
  }
`;

export default Close;
