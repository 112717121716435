import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Input from './Input';

import { useAppState } from '../hooks/useAppContext';

const Container = styled.div`
  text-align: center;
`;

const Field = styled.div`
  padding: 0 0 0.5rem;

  &:last-child {
    padding: 0;
  }
`;

const ModalPassword = () => {
  const {
    password: { loading, valid, value },
    passwordConfirm: { valid: validConfirm, value: valueConfirm },
    onPasswordChange,
    onPasswordConfirmChange,
    updatePassword,
    fetchProfile,
    closeModal,
  } = useAppState();
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updatePassword();
      await fetchProfile();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  // Validation
  const hasUpperCase = value && /[A-Z]/.test(value);
  const hasLowerCase = value && /[a-z]/.test(value);
  const hasNumbers = value && /\d/.test(value);
  const hasNonalphas = value && /\W/.test(value);
  const hasMinEight = value && value.length > 0 && value.length >= 8;
  const hasMaxThirty = value && value.length > 0 && value.length <= 30;
  const hasMatch = value === valueConfirm;

  return (
    <Container>
      <form onSubmit={handleUpdate}>
        <Field>
          <h5>Change password</h5>
          <p style={{ color: '#adadad' }}>
            Update the password associated with this account.
            <br />
            <br />
            <span style={{ color: hasUpperCase && 'green' }}>
              Min 1 uppercase letter
            </span>
            ,{' '}
            <span style={{ color: hasLowerCase && 'green' }}>
              Min 1 lowercase letter
            </span>
            ,{' '}
            <span style={{ color: hasNonalphas && 'green' }}>
              Min 1 special character
            </span>
            , <span style={{ color: hasNumbers && 'green' }}>Min 1 number</span>
            ,{' '}
            <span style={{ color: hasMinEight && 'green' }}>
              Min 8 characters
            </span>
            ,{' '}
            <span style={{ color: hasMaxThirty && 'green' }}>
              Max 30 characters
            </span>
            ,{' '}
            <span style={{ color: hasMatch && 'green' }}>Passwords match</span>
          </p>
        </Field>
        <Field>
          <Input
            placeholder="New password"
            onChange={(e) => onPasswordChange(e.target.value)}
            valid={valid}
            type="password"
          />
        </Field>
        <Field>
          <Input
            placeholder="Confirm new password"
            onChange={(e) => onPasswordConfirmChange(e.target.value, value)}
            valid={validConfirm}
            type="password"
          />
        </Field>
        <Field>
          <Button
            onClick={handleUpdate}
            disabled={!valid || !validConfirm}
            loading={loading}
          >
            Update
          </Button>
        </Field>
      </form>
    </Container>
  );
};
export default ModalPassword;
