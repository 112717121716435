export const encodeHashtag = (string) => {
  return string?.replace(/#/, '%23');
};

export const mapImageToResults = (response) => {
  return (
    response?.results?.map((result) => {
      const smallBaseUrl = response?.images_base_urls?.small;
      const largeBaseUrl = response?.images_base_urls?.large;
      const path = largeBaseUrl && result?.images?.[0]?.path;
      const thumbnail = smallBaseUrl && result?.images?.[0]?.path;
      return {
        ...result,
        path: path && `${largeBaseUrl}${encodeHashtag(path)}`,
        thumbnail: thumbnail && `${smallBaseUrl}${encodeHashtag(thumbnail)}`,
      };
    }) || []
  );
};
