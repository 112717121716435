import styled from 'styled-components';

const Burger = styled.div`
  height: 14px;
  position: relative;
  width: 40px;

  &:before,
  &:after {
    background: #000;
    border-radius: 4px;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: transform 0.2s ease;
    width: 100%;
  }

  &:before {
    top: 0;
    transform: ${(props) => props.open && 'translate3d(0,6px,0) rotate(45deg)'};
  }

  &:after {
    bottom: 0;
    transform: ${(props) =>
      props.open && 'translate3d(0,-6px,0) rotate(-45deg)'};
  }
`;

export default Burger;
