// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import styled from 'styled-components';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

const SliderContainer = styled.div`
  padding: 0 2rem;
  overflow: hidden;
`;

const Slider = styled(Swiper)`
  overflow: visible;

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-button-prev {
    left: -2rem;
  }

  .swiper-button-next {
    right: -2rem;
  }
`;

const ArtworkSlider = ({ slides, ...props }) => {
  return slides?.length ? (
    <SliderContainer>
      <Slider
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        spaceBetween={50}
        navigation={true}
        pagination={{ type: 'fraction' }}
        preventClicks={false}
        preventClicksPropagation={false}
        centeredSlides={true}
        {...props}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={`${index}`}>{slide}</SwiperSlide>
        ))}
      </Slider>
    </SliderContainer>
  ) : null;
};

export default ArtworkSlider;
