import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import gsap from 'gsap';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import Accordion from './Accordion';
import FadeIn from './FadeIn';
import InputFilter from './InputFilter';
import RangeSlider from './RangeSlider';
import SearchByTitle from './SearchByTitle';
import SimpleButton from './SimpleButton';
import SortDropdown from './SortDropdown';

import { useAppState } from '../hooks/useAppContext';
import { ARTWORK_TYPE, MEDIUM_TYPE, getSourceStr } from '../shared/utils';
import {
  MENU_HEIGHT,
  MENU_HEIGHT_MOBILE,
  FILTERS_HEIGHT_DESKTOP,
  FILTERS_HEIGHT_TABLET,
  FILTERS_HEIGHT_MOBILE,
  SEARCH_FILTERS_TOP_OFFSET,
} from '../utils/constants';

const ContainerContainer = styled.div`
  background: #f1f1f1;
  padding-top: ${FILTERS_HEIGHT_MOBILE}px;

  ${(props) =>
    props.isTablet &&
    css`
      padding-top: ${FILTERS_HEIGHT_TABLET}px;
    `}

  ${(props) =>
    props.isDesktop &&
    css`
      padding-top: ${FILTERS_HEIGHT_DESKTOP}px;
    `}
`;

const Container = styled.div`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: fixed;
  top: ${MENU_HEIGHT_MOBILE}px;
  transform: translateY(0);
  transition: transform 0.4s ease-in-out;
  z-index: 1;

  ${(props) =>
    !props.isMobile &&
    css`
      top: ${MENU_HEIGHT}px;
    `}

  ${(props) =>
    props.isHidden &&
    css`
      transform: translateY(-100%);
    `}
`;

const Wrapper = styled.div`
  align-items: center;
  background: #fff;
  color: #444444;
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  padding: 1rem;
  width: 100%;

  ${(props) =>
    !props.isDesktop &&
    css`
      flex-direction: column;
      > * {
        width: 100%;
        margin: 5px auto;
        max-width: 100%;
      }
    `}
`;

const Text = styled.div`
  font-size: 0.875rem;
  margin-right: 1rem;
  min-width: 60px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: #838383;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`;

const Mask = styled.div`
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: fixed;
  top: ${MENU_HEIGHT}px;
  visibility: hidden;
  width: 100%;
  z-index: 1;

  ${(props) =>
    props.isMobile &&
    css`
      height: calc(100% - ${MENU_HEIGHT_MOBILE}px);
      top: ${MENU_HEIGHT_MOBILE}px;
    `}
`;

const Overlay = styled.div`
  background: #fff;
  border-right: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${MENU_HEIGHT}px);
  justify-content: space-between;
  left: 0;
  max-width: 400px;
  opacity: 0;
  overflow-y: scroll;
  position: fixed;
  top: ${MENU_HEIGHT}px;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.65, 0.05, 0.36, 1),
    opacity 0.1s linear 0.5s;
  width: 100%;
  z-index: 2;

  ${(props) =>
    props.isMobile &&
    css`
      height: calc(100% - ${MENU_HEIGHT_MOBILE}px);
      top: ${MENU_HEIGHT_MOBILE}px;
    `}

  ${(props) => props.open && OverlayOpen}
`;

const OverlayOpen = css`
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s cubic-bezier(0.65, 0.05, 0.36, 1),
    opacity 0.1s linear;
`;

const OverlayInner = styled.div`
  padding: 2rem;
`;

const Footer = styled.div`
  display: flex;
  border-top: 1px solid #d0d0d0;
  padding: 1.5rem;
`;

const Radio = styled.div`
  color: #000;
  cursor: pointer;
  display: flex;
  margin-bottom: 0.75rem;
  position: relative;

  &:before {
    border: 1px solid #d0d0d0;
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    margin-right: 0.5rem;
    width: 20px;
  }

  ${(props) => props.selected && RadioSelected}
`;

const RadioSelected = css`
  font-family: 'ArtWishListMedium';
  &:after {
    background: #000;
    border-radius: 50%;
    content: '';
    display: block;
    height: 6px;
    left: 7px;
    position: absolute;
    top: 7px;
    width: 6px;
  }
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  ${(props) =>
    props.spread &&
    css`
      flex: 1;
    `}

  ${(props) =>
    props.space &&
    css`
      justify-content: space-between;
    `}
`;

const SimpleButtonSourceType = styled(SimpleButton)`
  min-width: 140px;

  ${(props) =>
    props.isMobile &&
    css`
      margin: 0;
      flex: 1;
    `}
`;

const toTitleCase = (str) => {
  return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const sourceTypes = Object.keys(ARTWORK_TYPE).map((key) => ({
  key: toTitleCase(getSourceStr(ARTWORK_TYPE[key])),
  id: ARTWORK_TYPE[key],
}));

const mediumTypes = Object.keys(MEDIUM_TYPE).map((key) => ({
  key: toTitleCase(key),
  id: MEDIUM_TYPE[key],
}));

const SearchFilters = () => {
  const {
    artworkRef,
    filtersContainerRef,
    handleApplyFilters,
    handleSearchByTitleInputClear,
    heightMax,
    heightMin,
    imagePreview,
    isFiltersVisible,
    media,
    medium,
    salePriceMax,
    salePriceMin,
    saleYearMax,
    saleYearMin,
    searchContainerRef,
    searchFiltersOverlayVisible,
    searchResultsState,
    setHeightMax,
    setHeightMin,
    setIsFiltersVisible,
    setSalePriceMax,
    setSalePriceMin,
    setSaleYearMax,
    setSaleYearMin,
    setSearchFiltersOverlayVisible,
    setSourceType,
    setMedium,
    setWidthMax,
    setWidthMin,
    setYearOfWorkMax,
    setYearOfWorkMin,
    sourceType,
    widthMax,
    widthMin,
    yearOfWorkMax,
    yearOfWorkMin,
  } = useAppState();
  const mask = useRef(null);
  const overlayRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [scrollStatus, setScrollStatus] = useState(0);

  const closeOverlay = (event) => {
    if (event?.key === 'Escape') {
      setSearchFiltersOverlayVisible(false);
    }
  };

  useEffect(() => {
    if (!searchContainerRef?.current) return;
    const autoAlpha = searchFiltersOverlayVisible ? 1 : 0;
    gsap.to(mask?.current, {
      autoAlpha,
      onComplete: () => {
        const disableScroll = () => {
          if (overlayRef?.current) {
            disableBodyScroll(overlayRef?.current);
          }
        };
        const enableScroll = () => {
          if (overlayRef?.current) {
            enableBodyScroll(overlayRef?.current);
          }
        };
        searchFiltersOverlayVisible ? disableScroll() : enableScroll();
      },
    });

    if (searchFiltersOverlayVisible) {
      window.addEventListener('keydown', closeOverlay);
    } else {
      window.removeEventListener('keydown', closeOverlay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFiltersOverlayVisible]);

  useEffect(() => {
    setIsFiltersVisible(true);
    const searchContainerRefVar = searchContainerRef?.current;
    window.addEventListener('scroll', handleScroll);
    return () => {
      if (searchContainerRefVar) {
        searchContainerRefVar.style = '';
      }
      document.body.style = '';
      clearAllBodyScrollLocks();
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    if (filtersContainerRef?.current) {
      filtersContainerRef.current.style = '';
    }

    setScrollStatus((prev) => {
      const scroll = -document.body.getBoundingClientRect().top;
      const scrollingUp = scroll < prev;
      const scrollingDown = scroll > prev;
      const offset = 500;
      const topOffset = SEARCH_FILTERS_TOP_OFFSET;

      if (artworkRef?.current) {
        setIsFiltersVisible(false);
        return;
      }

      if (scroll > topOffset) {
        setIsFiltersVisible(scroll < prev);
      } else {
        setIsFiltersVisible(true);
      }

      if (scrollingDown && scroll < prev + offset) {
        return prev;
      }

      if (scrollingUp && scroll > prev - offset) {
        return prev;
      }

      return scroll;
    });
  };

  const searchFiltersVisible = useMemo(() => {
    return searchResultsState.data?.results && !imagePreview;
  }, [searchResultsState.data, imagePreview]);

  return searchFiltersVisible ? (
    <>
      <FadeIn>
        <ContainerContainer {...media}>
          <Container
            ref={filtersContainerRef}
            {...media}
            isHidden={!isFiltersVisible}
          >
            <Wrapper {...media}>
              <Row spread>
                <Text>Filters</Text>
                <SimpleButtonSourceType
                  inline
                  secondary
                  onClick={() => setSearchFiltersOverlayVisible(true)}
                  {...media}
                >
                  {sourceTypes.find(({ key, id }) => +sourceType === id)?.key ||
                    'Source type'}
                  <Icon icon={faChevronDown} style={{ width: 12 }} />
                </SimpleButtonSourceType>
                {!media.isMobile && <SearchByTitle />}
              </Row>
              <Row>
                <Text>Sort by</Text>
                <SortDropdown />
              </Row>
              {media.isMobile && <SearchByTitle />}
            </Wrapper>
          </Container>
        </ContainerContainer>
      </FadeIn>
      <Mask
        ref={mask}
        onClick={() => setSearchFiltersOverlayVisible(false)}
        {...media}
      />
      <Overlay ref={overlayRef} open={searchFiltersOverlayVisible} {...media}>
        <OverlayInner>
          <Accordion label="Source Type" isOpen>
            <Radio selected={!sourceType} onClick={() => setSourceType()}>
              All
            </Radio>
            {sourceTypes.map(({ key, id }) => (
              <Radio
                key={id}
                value={id}
                onClick={() => setSourceType(id)}
                selected={+sourceType === id}
              >
                {key}
              </Radio>
            ))}
          </Accordion>
          <Accordion label="Medium">
            <Radio selected={medium === undefined} onClick={() => setMedium()}>
              All
            </Radio>
            {mediumTypes.map(({ key, id }) => (
              <Radio
                key={id}
                value={id}
                onClick={() => setMedium(id)}
                selected={+medium === id}
              >
                {key}
              </Radio>
            ))}
          </Accordion>
          {sourceType === 1 && (
            <Accordion label="Sale Price">
              <RangeSlider
                min="1"
                max="150000000"
                onChangeMin={setSalePriceMin}
                onChangeMax={setSalePriceMax}
                minValue={salePriceMin || 1}
                maxValue={salePriceMax || 150000000}
              />
              <InputFilter
                dollar
                fromLabel="Minimum"
                fromOnChange={setSalePriceMin}
                fromValue={salePriceMin}
                toLabel="Maximum"
                toOnChange={setSalePriceMax}
                toValue={salePriceMax}
              />
              <InputFilter
                fromLabel="Sale Year"
                fromOnChange={setSaleYearMax}
                fromValue={saleYearMax}
                toLabel=""
                toMax={new Date().getFullYear()}
                toOnChange={setSaleYearMin}
                toValue={saleYearMin}
              />
            </Accordion>
          )}
          <Accordion label="Other">
            <InputFilter
              fromLabel="Creation Year"
              fromOnChange={setYearOfWorkMin}
              fromValue={yearOfWorkMin}
              toLabel=""
              toMax={new Date().getFullYear()}
              toOnChange={setYearOfWorkMax}
              toValue={yearOfWorkMax}
            />
            <InputFilter
              fromLabel="Height (cm)"
              fromOnChange={setHeightMin}
              fromValue={heightMin}
              toLabel=""
              toOnChange={setHeightMax}
              toValue={heightMax}
            />
            <InputFilter
              fromLabel="Width (cm)"
              fromOnChange={setWidthMin}
              fromValue={widthMin}
              toLabel=""
              toOnChange={setWidthMax}
              toValue={widthMax}
            />
          </Accordion>
        </OverlayInner>
        <Footer>
          <SimpleButton
            secondary
            onClick={() => {
              handleApplyFilters('All', handleSearchByTitleInputClear);
              setSearchFiltersOverlayVisible(false);
            }}
          >
            Clear all
          </SimpleButton>
          <SimpleButton
            onClick={() => {
              handleApplyFilters(sourceType);
              setSearchFiltersOverlayVisible(false);
            }}
          >
            Apply
          </SimpleButton>
        </Footer>
      </Overlay>
    </>
  ) : null;
};

export default SearchFilters;
