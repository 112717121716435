import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import NotFound from './pages/NotFound';
import Search from './pages/Search';
import Settings from './pages/Settings';
import Profile from './pages/Profile';

import DragAndDropContainer from './components/DragAndDropContainer';
import Header from './components/Header';

import { AppProvider } from './hooks/useAppContext';

import './App.css';

const App = () => (
  <AppProvider>
    <DragAndDropContainer>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/search" replace />} />
        <Route path="/search" element={<Search />} />
        <Route path="/artwork/:id" element={<Search />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </DragAndDropContainer>
  </AppProvider>
);

export default App;
