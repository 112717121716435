export const ArtworkField = ({ field, label }) => {
  return field ? (
    <>
      {label && (
        <div className="mt-3" style={{ fontFamily: 'ArtWishListMedium' }}>
          {label}
        </div>
      )}
      {field && <div>{field}</div>}
    </>
  ) : null;
};
