import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  return {
    height: window?.innerHeight || 0,
    width: window?.innerWidth || 0,
  };
};

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth < 992,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth < 532);
  const [media, setMedia] = useState({
    isDesktop,
    isTablet,
    isMobile,
    isSmallMobile,
  });

  useEffect(() => {
    function handleResize() {
      window &&
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      const mediaQueries = {
        isDesktop: window.innerWidth >= 992,
        isTablet: window.innerWidth >= 768 && window.innerWidth < 992,
        isMobile: window.innerWidth < 768,
        isSmallMobile: window.innerWidth < 532,
      };
      setIsDesktop(mediaQueries.isDesktop);
      setIsTablet(mediaQueries.isTablet);
      setIsMobile(mediaQueries.isMobile);
      setIsSmallMobile(mediaQueries.isSmallMobile);
      setMedia(mediaQueries);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowDimensions,
    ...media,
    media,
  };
}

export default useWindowDimensions;
