import { useRef, useState } from 'react';

function useSearchFilters() {
  const filtersContainerRef = useRef(null);
  const [searchFiltersOverlayVisible, setSearchFiltersOverlayVisible] =
    useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  return {
    filtersContainerRef,
    isFiltersVisible,
    searchFiltersOverlayVisible,
    setIsFiltersVisible,
    setSearchFiltersOverlayVisible,
  };
}

export default useSearchFilters;
