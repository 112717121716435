export const getCatalogueNumber = (artwork) => {
  if (artwork?.catalogue_number?.startsWith('v')) {
    return `Volume ${artwork?.catalogue_number
      ?.split('_')?.[0]
      ?.slice(1)}, No. ${artwork?.catalogue_number?.split('_')?.[1]}`;
  }
  if (artwork?.catalogue_number) {
    return `No. ${artwork?.catalogue_number}`;
  }
  return '';
};

export const getTitle = (artwork) => {
  if (artwork?.title && artwork?.year_of_work_verbose) {
    return `${artwork?.title}, ${artwork?.year_of_work_verbose}`;
  }
  if (artwork?.title) {
    return `${artwork?.title}`;
  }
  if (artwork?.year_of_work_verbose) {
    return `${artwork?.year_of_work_verbose}`;
  }
  return '';
};

export const getDescriptionGl = (artwork) => {
  if (artwork?.description_gl) {
    return `${artwork?.description_gl}`;
  }
  return '';
};

export const getSize = (artwork) => {
  const unit = artwork?.size_unit === 'in' ? 'inches' : artwork?.size_unit;
  let size = '';
  if (artwork?.size_height && artwork?.size_width) {
    size = `${artwork?.size_height} x ${artwork?.size_width} ${unit}`;
  } else if (artwork?.size_height) {
    size = `${artwork?.size_height} ${unit}`;
  } else if (artwork?.size_width) {
    size = `${artwork?.size_width} ${unit}`;
  }
  return size;
};

export const getDate = (artwork) => {
  let date = null;
  if (artwork?.date) {
    const dateObj = new Date(artwork?.date);
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    date = `${dateObj.getDate()}  ${
      monthNames[dateObj.getMonth()]
    } ${dateObj.getFullYear()}`;
  }
  return date;
};
