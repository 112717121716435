export const searchTypes = {
  ARTIST: 'artist',
  COLLECTION: 'collection',
  TITLE: 'title',
};

export const searchTypePlaceholder = {
  ARTIST: 'Search by artist...',
  COLLECTION: 'Search by collection, provenance...',
  TITLE: 'Search by title, description...',
};

export const searchTypeButton = {
  ARTIST: 'or search by artist',
  COLLECTION: 'or search by collection',
};
