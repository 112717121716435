import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import FadeIn from './FadeIn';

const StyledImage = styled.img`
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 0.5s ease;
`;

const LazyImage = ({ src, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const srcImage = useMemo(() => src, [src]);
  return (
    <>
      {!hasError && (
        <StyledImage
          {...props}
          src={srcImage}
          onLoad={() => {
            setTimeout(() => {
              setLoading(false);
            }, 100);
          }}
          onError={() => {
            setHasError(true);
          }}
          loaded={!loading}
        />
      )}
      {hasError && (
        <FadeIn>
          <span>No image available</span>
        </FadeIn>
      )}
    </>
  );
};

export default LazyImage;
