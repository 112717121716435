import React from 'react';
import styled, { css } from 'styled-components';

import SvgSearch from './SvgSearch';
import { useAppState } from '../hooks/useAppContext';
import { searchTypePlaceholder } from '../constants/search';

const InputField = styled.div`
  background: #f3f3f3;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #a1a1a1;
  display: flex;
  max-width: ${(props) => (props.fullWidth ? '100%' : '300px')};
  min-height: 40px;
  padding-left: 0.5rem;
  width: 100%;

  &:focus-within {
    border-color: #d0d0d0;
    background: #fff;
  }

  ${(props) =>
    !props.isDesktop &&
    css`
      flex: 1;
      max-width: none;
    `}
`;

const Input = styled.input`
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  min-height: 38px;
  outline: none;
  padding-left: 0.5rem;
  width: 100%;
`;

const Form = styled.form`
  position: relative;
  width: 100%;
`;

const SearchByTitle = (props) => {
  const {
    handleSearchByTitleInputChange,
    searchByTitleInputRef,
    submitSearchByTitleForm,
    setSearchState,
    searchState,
    media,
  } = useAppState();
  return (
    <InputField {...props} {...media}>
      <SvgSearch width={26} />
      <Form
        onSubmit={(e) =>
          submitSearchByTitleForm(e, setSearchState, searchState)
        }
      >
        <Input
          placeholder={searchTypePlaceholder.TITLE}
          ref={searchByTitleInputRef}
          onChange={handleSearchByTitleInputChange}
          fullWidth
          {...media}
        />
      </Form>
    </InputField>
  );
};

export default SearchByTitle;
