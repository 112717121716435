import { useState } from 'react';

import api from '../utils/api';

function useBilling() {
  const initialState = { data: null, error: null, loading: null };
  const [billingLink, setBillingLink] = useState();
  const [billingLinkState, setState] = useState(initialState);

  const fetchBillingLink = async () => {
    setState({ ...initialState, loading: true });
    try {
      const response = await api(
        'https://api.facture.art/payments/billing-portal',
      );
      const data = response?.goto;
      setState({ ...initialState, data });
      setBillingLink(data);
    } catch (error) {
      setState({ ...initialState, error });
      console.log(error);
    }
  };

  const openBillingLink = () => {
    if (billingLink) {
      window.location.href = billingLink;
    }
  };

  return {
    billingLink,
    billingLinkState,
    fetchBillingLink,
    openBillingLink,
  };
}

export default useBilling;
