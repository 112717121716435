import styled from 'styled-components';

const Page = styled.div`
  margin: 0 auto;
  max-width: 1140px;
  padding: 1rem 2rem;
  width: 100%;
`;

export default Page;
