import React, { useRef } from 'react';
import styled from 'styled-components';

import SvgCamera from './SvgCamera';
import { useAppState } from '../hooks/useAppContext';

const Button = styled.div`
  align-items: center;
  background: ${(props) => (props.active ? '#f8ee23' : '#f3f3f3')};
  border-radius: 20px;
  color: #000;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  margin-right: ${(props) => (props.fullWidth ? '0' : '1rem')};
  margin-bottom: ${(props) => (props.fullWidth ? '1rem' : '0')};
  margin-top: ${(props) => (props.fullWidth ? '1rem' : '0')};
  padding: 1rem;
  text-transform: uppercase;
  width: ${(props) => (props.fullWidth ? '100%' : '150px')};

  &:hover {
    background: #f8ee23;
  }
`;

const CameraIcon = styled(SvgCamera)`
  margin-right: 0.5rem;
  width: 22px;
`;

const UploadButton = (props) => {
  const { handleUploadClick, handleImagePreview, isUploading } = useAppState();

  const inputRef = useRef(null);
  return (
    <Button
      {...props}
      active={isUploading}
      onClick={() => handleUploadClick(inputRef)}
    >
      <CameraIcon />
      Upload
      <input
        type="file"
        ref={inputRef}
        onChange={() => handleImagePreview(inputRef)}
        style={{ display: 'none' }}
        accept="image/*"
      />
    </Button>
  );
};

export default UploadButton;
