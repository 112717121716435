import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  margin-bottom: 1rem;
`;

const Input = styled.input`
  align-items: center;
  background: #fff;
  border-radius: 2rem;
  border: 1px solid #d0d0d0;
  color: #000;
  cursor: pointer;
  display: flex;
  font-family: ArtwishlistMedium;
  outline: 0;
  padding: 0.8rem;
  position: relative;
  width: 160px;

  ${(props) =>
    props.isFrom &&
    css`
      padding-left: 66px;
    `}

  ${(props) =>
    props.isTo &&
    css`
      padding-left: 46px;
    `}

    ${(props) =>
    props.dollar &&
    css`
      padding-left: 30px;
    `}
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  position: relative;
`;

const Label = styled.div`
  padding-bottom: 0.5rem;
  min-height: 30px;
`;

const InputLabel = styled.div`
  bottom: 13px;
  left: 18px;
  position: absolute;

  ${(props) =>
    props.dollar &&
    css`
      font-family: ArtwishlistMedium;
    `}
`;

const InputFilter = ({
  dollar,
  fromLabel,
  fromMax,
  fromMin,
  fromOnChange,
  fromValue,
  toLabel,
  toMax,
  toMin,
  toOnChange,
  toValue,
}) => (
  <Container>
    <Row>
      <InputContainer>
        <Label>{fromLabel}</Label>
        <Input
          isFrom
          dollar={dollar}
          onChange={(e) => {
            if (!e.target.value) {
              return fromOnChange('');
            }
            return !isNaN(+e.target.value)
              ? fromOnChange(+e.target.value)
              : fromOnChange(fromValue);
          }}
          max={fromMax}
          min={fromMin}
          value={fromValue}
        />
        <InputLabel dollar={dollar}>{dollar ? '$' : 'From:'}</InputLabel>
      </InputContainer>
      <InputContainer>
        <Label>{toLabel}</Label>
        <Input
          isTo
          dollar={dollar}
          onChange={(e) => {
            if (!e.target.value) {
              return toOnChange('');
            }
            return !isNaN(+e.target.value)
              ? toOnChange(+e.target.value)
              : toOnChange(toValue);
          }}
          max={toMax}
          min={toMin}
          value={toValue}
        />
        <InputLabel dollar={dollar}>{dollar ? '$' : 'To:'}</InputLabel>
      </InputContainer>
    </Row>
  </Container>
);

export default InputFilter;
