import { useEffect, useState } from 'react';

function useImageFetch(result) {
  const [image, setImage] = useState(null);

  const handleImage = (src, sourceApi) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject(img);
      if (sourceApi) {
        const pk = result?.images?.[0]?.pk;
        const imageUrl = `${process.env.REACT_APP_API_URL}/api/aw_lots/image/${pk}`;
        img.src = imageUrl;
      } else {
        img.src = src;
      }
    });
  };

  const getImageUrl = async () => {
    const imageSrc = result?.thumbnail || result?.path;

    if (!imageSrc) {
      setImage('Error');
      return;
    }

    try {
      await handleImage(imageSrc);
      setImage(imageSrc);
      return imageSrc;
    } catch (error) {
      console.log('Error fetching image');
    }

    try {
      await handleImage(imageSrc, true);
      setImage(imageSrc);
      return imageSrc;
    } catch (error) {
      console.log('Error fetching image from source API');
    }

    setImage('Error');
  };

  useEffect(() => {
    getImageUrl();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return {
    image,
  };
}

export default useImageFetch;
