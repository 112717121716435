import Cookies from 'universal-cookie';

export const getToken = () => {
  const cookies = new Cookies();
  const cookieToken = cookies.get('token');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let paramToken;
  if (params?.token) {
    paramToken = params.token;
  }
  if (cookieToken) {
    localStorage.setItem('token', cookieToken);
  }
  if (paramToken) {
    localStorage.setItem('token', paramToken);
  }
  const localToken = localStorage.getItem('token');
  const token = cookieToken || localToken;
  return token;
};

export default async function api(url, options, body) {
  try {
    const token = await getToken();
    const args = {
      ...options,
      headers: {
        Authorization: `Token ${token}`,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    };
    if (body) {
      args.body = JSON.stringify(body);
      args.headers = {
        ...args.headers,
        'Content-Type': 'application/json',
      };
    }
    const response = await fetch(url, args);
    if (response.status !== 200) {
      throw new Error('Not authorised');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
