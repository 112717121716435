import { ArtworkField } from './ArtworkField';

export const ArtworkPrivateCollection = ({ artwork }) => {
  return (
    <div style={{ fontSize: '13px' }}>
      <div className="mt-3" style={{ fontSize: '24px' }}>
        {' '}
        {artwork?.artist}{' '}
      </div>
      <div className="mt-1 text-justify" style={{ fontSize: '18px' }}>
        {artwork?.full_title}
      </div>

      <div className="mt-3">{artwork?.materials}</div>
      <div>{artwork?.size}</div>
      <div>{artwork?.duration}</div>

      <div style={{ fontFamily: 'ArtWishListMedium' }}>
        <ArtworkField field={artwork?.collection} label="" />
        <ArtworkField field={artwork?.location} label="" />
        <ArtworkField field={artwork?.sales_house} label="" />
      </div>

      <div className="mt-3 text-justify">
        <ArtworkField field={artwork?.acquisition} label="Acquisition Method" />
        <ArtworkField
          field={artwork?.inventory_number}
          label="Inventory Number"
        />
        <ArtworkField
          field={artwork?.catalogue_number}
          label="Catalogue Number"
        />

        <ArtworkField field={artwork?.description} label="Description" />
        <ArtworkField field={artwork?.inscriptions} label="Incriptions" />
        <ArtworkField field={artwork?.misc} label="Miscellaneous" />

        <ArtworkField
          field={artwork?.year_of_casting}
          label="Year Of Casting"
        />
        <ArtworkField field={artwork?.foundry} label="Foundry" />
        <ArtworkField field={artwork?.edition} label="Edition" />
        <ArtworkField field={artwork?.printer} label="Printer" />
        <ArtworkField field={artwork?.publisher} label="Publisher" />
        <ArtworkField field={artwork?.rights} label="Rights" />

        <ArtworkField field={artwork?.provenance} label="Provenance" />
        <ArtworkField
          field={artwork?.exhibition_history}
          label="Exhibition History"
        />
        <ArtworkField field={artwork?.literature} label="Literature" />
      </div>
    </div>
  );
};
