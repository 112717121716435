import { searchTypes } from '../constants/search';

export const getSearchEndpoint = (searchType) => {
  if (searchType === searchTypes.COLLECTION) {
    return '_search_by_collection';
  }

  if (searchType === searchTypes.ARTIST) {
    return '_artist_search';
  }

  if (searchType === searchTypes.TITLE) {
    return '_search_by_title';
  }

  return '_search';
};
