import React from 'react';
import styled from 'styled-components';

import FadeIn from './FadeIn';
import LazyImage from './LazyImage';

import { useAppState } from '../hooks/useAppContext';

const Container = styled.div`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Wrapper = styled.div`
  align-items: flex-start;
  color: #444444;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1140px;
  padding: 1rem;
  width: 100%;
`;

const StyledImage = styled(LazyImage)`
  max-height: 100px;
`;

const Text = styled.div`
  margin: 0 0 1rem;
`;

const ImagePreview = () => {
  const { imagePreview } = useAppState();
  return (
    imagePreview && (
      <FadeIn>
        <Container>
          <Wrapper>
            <Text>Your uploaded image:</Text>
            <StyledImage src={imagePreview} alt="" />
          </Wrapper>
        </Container>
      </FadeIn>
    )
  );
};

export default ImagePreview;
