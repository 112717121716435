import { useState } from 'react';

import api from '../utils/api';
import {
  getEstimate,
  getEstimateCurrency,
  getPrice,
  getSourceName,
  getSourceStr,
} from '../shared/utils';
import { mapImageToResults } from '../shared/mapping';
import {
  getCatalogueNumber,
  getDate,
  getSize,
  getTitle,
} from '../utils/contentHelpers';

function useArtwork() {
  const initialState = { data: null, error: null, loading: null };
  const [artworkState, setArtworkState] = useState(initialState);
  const [artwork, setArtwork] = useState();
  const [artworkInContextState, setArtworkInContextState] =
    useState(initialState);
  const [artworkInContext, setArtworkInContext] = useState([]);

  const fetchArtwork = async (id) => {
    setArtwork();
    setArtworkState({ ...initialState, loading: true });
    try {
      const response = await api(`https://api.facture.art/api/aw_lots/${id}`);
      const data = {
        ...response,
        catalogue_number: getCatalogueNumber(response),
        category: getSourceStr(response?.source_category),
        date: getDate(response),
        full_title: getTitle(response),
        online_source_name: getSourceName(response?.source_type),
        sales_estimate: getEstimate(response?.estimate),
        sales_estimate_currency: getEstimateCurrency(response?.estimate),
        sales_prices: getPrice(
          response?.sales_prices,
          getEstimateCurrency(response?.estimate),
        ),
        size: getSize(response),
      };
      setArtworkState({ ...initialState, data });
      setArtwork(data);
    } catch (error) {
      setArtworkState({ ...initialState, error });
      console.log(error);
    }
  };

  const fetchArtworkInContext = async (id, artist) => {
    if (!id || !artist) return;
    setArtworkInContextState({ ...initialState, loading: true });
    try {
      const response = await api(
        `https://api.facture.art/api/aw_lots/_search?artist_pk=${artist}&context=${id}`,
      );
      const data = mapImageToResults(response) || [];
      setArtworkInContextState({ ...initialState, data });
      setArtworkInContext(data);
    } catch (error) {
      setArtworkInContextState({ ...initialState, error });
      console.log(error);
    }
  };

  return {
    artwork,
    artworkInContext,
    artworkInContextState,
    artworkState,
    fetchArtwork,
    fetchArtworkInContext,
    setArtworkInContext,
  };
}

export default useArtwork;
