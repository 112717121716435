import React from 'react';
import styled, { css } from 'styled-components';

import { useAppState } from '../hooks/useAppContext';
import FadeIn from './FadeIn';
import LazyImage from './LazyImage';
import Loader from './Loader';
import ArtworkSlider from './ArtworkSlider';
import { ArtworkData } from './Artwork/ArtworkData';
import { encodeHashtag } from '../shared/mapping';

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;

  ${(props) =>
    props.isMobile &&
    css`
      display: block;
    `};

  ${(props) =>
    props.isTablet &&
    css`
      padding: 2rem;
    `};

  ${(props) =>
    props.isDesktop &&
    css`
      padding: 4rem;
    `};
`;

const Col = styled.div`
  flex: 1;
  padding: 2rem;

  ${(props) =>
    props.isMobile &&
    css`
      padding: 2rem;
    `}

  ${(props) =>
    props.centerImage &&
    css`
      align-items: flex-start;
      display: flex;
      justify-content: center;
    `}
`;

const Slider = styled(ArtworkSlider)`
  max-width: 420px;
  width: 100%;

  ${(props) =>
    props.isTablet &&
    css`
      max-width: 220px;
    `}

  .swiper-slide {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 300px;
    width: 100%;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: #000;
    font-size: 30px;
    font-weight: bold;
  }
`;

const NoImage = styled.div`
  align-items: center;
  border: 1px solid #d0d0d0;
  display: flex;
  font-size: 14px;
  height: 400px;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const ArtworkModal = () => {
  const { artwork, artworkState, media } = useAppState();
  const loading = artworkState.loading;
  const imageBaseUrl = artwork?.images_base_urls?.large;
  const imageUrl = artwork?.images?.[0]?.path;
  return loading ? (
    <Loader />
  ) : (
    <FadeIn>
      <Container {...media}>
        <Col centerImage {...media}>
          {artwork?.images?.length < 2 ? (
            <LazyImage
              alt={artwork?.title}
              src={`${imageBaseUrl}${encodeHashtag(imageUrl)}`}
              style={{ maxHeight: '60vh', maxWidth: '100%' }}
              shadow
            />
          ) : (
            <Slider
              slides={artwork?.images?.map((image) => (
                <LazyImage
                  alt={artwork?.title}
                  src={`${imageBaseUrl}${encodeHashtag(image?.path)}`}
                  style={{ maxHeight: '60vh', maxWidth: '100%' }}
                />
              ))}
              {...media}
            />
          )}
          {!artwork?.images?.length && <NoImage>No image available</NoImage>}
        </Col>
        <Col style={{ overflowWrap: 'anywhere' }} {...media}>
          <ArtworkData artwork={artwork} />
        </Col>
      </Container>
    </FadeIn>
  );
};

export default ArtworkModal;
