import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getToken } from '../utils/api';
import { dataUrlToFile, getFileFromSvg } from '../shared/imageProcessing';
import { mapImageToResults } from '../shared/mapping';

function useDragAndDrop() {
  const navigate = useNavigate();
  const uploadInputRef = useRef(null);
  const initialState = { data: null, error: null, loading: null };
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadResults, setUploadResults] = useState();
  const [uploadResultsMap, setUploadResultsMap] = useState();
  const [uploadResultsState, setUploadResultsState] = useState(initialState);

  // Image search results
  useEffect(() => {
    const response = uploadResultsState?.data;
    if (response) {
      setUploadResultsMap(mapImageToResults(response));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResultsState]);

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    return () => window.removeEventListener('mouseup', onMouseUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadClick = (ref) => {
    ref.current?.click();
    setIsUploading(true);
  };

  const onMouseUp = () => {
    dragEnd();
  };

  const onDrag = (e) => {
    const isOffScreen = e.screenX === 0 && e.screenY === 0;
    if (isOffScreen) {
      dragEnd();
    }
  };

  const dragEnd = () => {
    setIsDragging(false);
    setIsUploading(false);
    window.removeEventListener('drag', onDrag);
    window.addEventListener('mouseup', onMouseUp);
  };

  const dragStart = () => {
    setIsDragging(true);
    setIsUploading(true);
    window.addEventListener('drag', onDrag);
    window.removeEventListener('mouseup', onMouseUp);
  };

  const handleDragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dragStart();
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dragStart();
  };

  const reset = async () => {
    dragEnd();
    setImagePreview(null);
    setUploadResultsState(initialState);
    setUploadResultsMap();
    setUploadResultsState({ ...initialState, loading: true });
    await navigate('/search');
  };

  const handleDrop = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await reset();

    const item = e.dataTransfer.items?.[0];
    const file = e.dataTransfer.files?.[0];
    const imageElement = e.dataTransfer.getData('text/html');

    let fileUpload = '';
    let imageBlob = '';

    // Handle dataTransfer.items file
    if (item?.kind === 'file') {
      fileUpload = item.getAsFile();
    }

    // Handle dataTransfer.files
    if (file) {
      fileUpload = file;
    }

    try {
      // Hanlde file upload
      if (fileUpload) {
        const upload = URL.createObjectURL(fileUpload);
        setImagePreview(upload);
        await navigate('/search');
        await handleFileUpload(fileUpload);
        return;
      }

      // Handle string
      if (!fileUpload && item?.kind === 'string') {
        const div = document.createElement('div');
        div.innerHTML = imageElement;
        const imageSrc = div.querySelector('img')?.src;

        // Handle SVG
        if (imageElement?.includes('<svg')) {
          const file = await getFileFromSvg(imageElement);
          setImagePreview(file);
          // Skip SVG upload
          // await handleFileUpload(file);
          setUploadResultsState({
            ...uploadResultsState,
            data: [],
            loading: false,
          });
          return;
        }

        if (imageSrc) {
          // Handle base64
          if (imageSrc?.includes(';base64')) {
            const file = await dataUrlToFile(imageSrc);
            setImagePreview(imageSrc);
            await handleFileUpload(file);
            return;
          }

          // Handle http
          if (imageSrc?.startsWith('http')) {
            try {
              setImagePreview(imageSrc);
              await handleFileUpload(null, imageSrc);
              return;
            } catch (error) {
              setUploadResultsState({ ...initialState, loading: false });
              setUploadResults();
            }
          }

          // Handle blob
          if (imageSrc?.startsWith('blob:')) {
            const file = await dataUrlToFile(imageSrc);
            setImagePreview(imageSrc);
            await handleFileUpload(file);
            return;
          }
        }
      }

      // Handle no results
      if (!fileUpload && !imageBlob) {
        await navigate('/search');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImagePreview = async (ref) => {
    const file = ref?.current?.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImagePreview(url);
      await navigate('/search');
      await handleFileUpload(file);
    }
  };

  async function scaleImageBeforeUpload(file, dimensions) {
    if (!file.type.match(/image.*/)) return null;
    const image = new Image();
    image.src = URL.createObjectURL(file);
    await new Promise((res) => (image.onload = res));
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d', { alpha: true });
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;
    if (image.height <= image.width) {
      const scaleProportions = canvas.height / image.height;
      const scaledWidth = scaleProportions * image.width;
      context.drawImage(
        image,
        (canvas.width - scaledWidth) / 2,
        0,
        scaledWidth,
        canvas.height,
      );
    } else {
      const scaleProportions = canvas.width / image.width;
      const scaledHeight = scaleProportions * image.height;
      context.drawImage(
        image,
        0,
        (canvas.height - scaledHeight) / 2,
        canvas.width,
        scaledHeight,
      );
    }
    return new Promise((res) => canvas.toBlob(res));
  }

  const handleFileUpload = async (file, fileSrc) => {
    setUploadResultsState({ ...initialState, loading: true });
    setUploadResults();
    try {
      const token = getToken();
      const options = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const formData = new FormData();
      if (file) {
        const resizedImage = await scaleImageBeforeUpload(file, {
          width: 1000,
          height: 1000,
        });
        formData.append('upload', resizedImage);
      }
      if (fileSrc) {
        formData.append('upload_url', fileSrc);
      }
      const baseUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${baseUrl}/api/aw_lots/_image_search`, {
        ...options,
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      setUploadResultsState({ ...initialState, data, loading: false });
      setUploadResults(data);
    } catch (error) {
      console.log(error);
      setUploadResultsState({ ...initialState, error, loading: false });
      setUploadResults();
    }
  };

  return {
    imagePreview,
    isDragging,
    isUploading,
    uploadInputRef,
    uploadResults,
    uploadResultsMap,
    uploadResultsState,
    handleUploadClick,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleImagePreview,
    setImagePreview,
    setUploadResults,
    setUploadResultsMap,
    setUploadResultsState,
  };
}

export default useDragAndDrop;
