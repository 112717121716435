import { useEffect, useState } from 'react';

function useModal() {
  const initialState = 'Default';
  const [modal, setModal] = useState(initialState);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      setModal('Default');
    }
  };

  const closeModal = () => {
    setModal('Default');
  };

  return {
    modal,
    closeModal,
    setModal,
  };
}

export default useModal;
