import styled from 'styled-components';

const green = '#29eb33';
const red = '#ff7f7f';

const Input = styled.input`
  background: #f3f3f3;
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: none;
  cursor: pointer;
  min-height: 38px;
  outline: none;
  padding: 0 1rem;
  width: 100%;

  &:focus-within {
    border-color: #f3f3f3;
    background: #fff;
  }

  border-color: ${(props) => props.valid === true && green};
  border-color: ${(props) => props.valid === false && red};
  &:focus-within {
    border-color: ${(props) => props.valid === true && green};
    border-color: ${(props) => props.valid === false && red};
  }
`;

export default Input;
