import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppState } from '../hooks/useAppContext';

const Icon = styled(FontAwesomeIcon)`
  color: #838383;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 12;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const Select = styled.select`
  align-items: center;
  background: #fff;
  border-radius: 2rem;
  border: 1px solid #d0d0d0;
  color: #000;
  cursor: pointer;
  display: flex;
  outline: 0;
  padding: 0.5rem 3rem 0.5rem 1rem;
  position: relative;

  // Remove default arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  ${(props) =>
    !props.isDesktop &&
    css`
      flex: 1;
    `}
`;

const SortDropdown = () => {
  const { searchState, setSearchState } = useAppState();
  const [isAuction, setIsAuction] = useState(false);

  useEffect(() => {
    setIsAuction(searchState?.source_type === 1);
  }, [searchState?.source_type]);
  return (
    <Wrapper>
      <Select
        onChange={(e) =>
          setSearchState({ ...searchState, sort_by: e.target.value })
        }
        value={searchState?.sort_by || 'Default'}
      >
        <option>Default</option>
        {isAuction && (
          <>
            <option value="date">Date of sale (descending)</option>
            <option value="-date">Date of sale (ascending)</option>
            <option value="sale_price">Sale price (descending)</option>
            <option value="-sale_price">Sale price (ascending)</option>
          </>
        )}
        <option value="sizes">Size (descending)</option>
        <option value="-sizes">Size (ascending)</option>
        <option value="year_of_work">Year of work (descending)</option>
        <option value="-year_of_work">Year of work (ascending)</option>
      </Select>
      <Icon icon={faChevronDown} />
    </Wrapper>
  );
};

export default SortDropdown;
