import { getToken } from '../utils/api';

// Axios
import axios from 'axios';

export const ARTWORK_TYPE = {
  AUCTION: 1,
  CATALOGUE_RAISSONE: 2,
  ONLINE: 3,
  MUSEUM: 4,
  PRIVATE_COLLECTION: 5,
};

export const MEDIUM_TYPE = {
  UNKNOWN: 0,
  INSTALLATIONS: 1,
  OTHER: 2,
  PAINTINGS: 3,
  PHOTOGRAPHS: 4,
  PRINTS: 5,
  SCULPTURES: 6,
  WORKS_ON_PAPER: 7,
};

export function getSourceStr(input) {
  switch (input) {
    case ARTWORK_TYPE.AUCTION:
      return 'AUCTION';
    case ARTWORK_TYPE.CATALOGUE_RAISSONE:
      return 'CATALOGUE RAISONNÉ';
    case ARTWORK_TYPE.ONLINE:
      return 'ONLINE';
    case ARTWORK_TYPE.MUSEUM:
      return 'MUSEUM';
    case ARTWORK_TYPE.PRIVATE_COLLECTION:
      return 'PRIVATE COLLECTION';
    default:
      return 'UNKNOWN';
  }
}

export function getSource(input) {
  // Devil's logic
  let source = '';

  // Auction
  if (['LA', 'A', 'P', 'Y', 'N'].includes(input)) source = ARTWORK_TYPE.AUCTION;

  // CatRais
  const catRaisList1 = ['K', 'L', 'B', 'E', 'R', 'G', 'J', 'C'];
  const catRaisList2 = [
    'O',
    'U',
    'H',
    'I',
    'S',
    'W',
    'M',
    '0',
    'Z',
    'JB',
    'GL',
    'CR',
    'MR',
  ];
  const catRaisList = [...catRaisList1, ...catRaisList2];
  if (catRaisList.includes(input)) source = ARTWORK_TYPE.CATALOGUE_RAISSONE;

  // Online
  if (['NN', 'YY', 'D', '1'].includes(input)) source = ARTWORK_TYPE.ONLINE;

  // Museum
  const museumList = [
    'Q',
    'X',
    'F',
    '9',
    '8',
    '7',
    '6',
    'RM',
    'MT',
    'AR',
    'NG',
  ];
  if (museumList.includes(input)) source = ARTWORK_TYPE.MUSEUM;

  // Private Collection
  const pcList1 = [
    'T',
    'V',
    '2',
    'TD',
    'EC',
    'BA',
    'HA',
    'CD',
    'AP',
    'FB',
    'SG',
    'TB',
    'BF',
  ];
  const pcList2 = [
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
  ];
  const pcList = [...pcList1, ...pcList2];
  if (pcList.includes(input)) source = ARTWORK_TYPE.PRIVATE_COLLECTION;

  return source;
}

export function getSourceName(input) {
  switch (input) {
    case 'NN':
      return 'Artnet';
    case 'YY':
      return 'Artsy';
    case 'D':
      return 'ArtStack';
    case '1':
      return 'Contemporary Art Dialy';
    default:
      return 'UNKNOWN';
  }
}

export function getCollectionName(input) {
  switch (input) {
    case 'Q':
      return 'Artnet';
    case 'X':
      return 'Artsy';
    case 'F':
      return 'ArtStack';
    case '9':
      return 'Contemporary Art Dialy';
    case '8':
      return 'Contemporary Art Dialy';
    case '7':
      return 'Contemporary Art Dialy';
    case '6':
      return 'Contemporary Art Dialy';
    case 'RM':
      return 'Contemporary Art Dialy';
    case 'MT':
      return 'Contemporary Art Dialy';
    case 'AR':
      return '-';
    default:
      return 'UNKNOWN';
  }
}

export function convertCurrency(currency) {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return currency;
  }
}

export async function stripeRedirectToCheckout() {
  const stripe = require('@stripe/stripe-js'); //to use built-in modules

  try {
    const token = getToken();
    const options = {
      headers: { Authorization: `Token ${token}` },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/payments/create-checkout-session`,
      {},
      options,
    );

    console.log('response ', response.data.session_id);
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    const stripeSession = await stripe.loadStripe(
      process.env.REACT_APP_STRIPE_KEY,
    );
    stripeSession.redirectToCheckout({ sessionId: response.data.session_id });
  } catch (e) {
    console.log('error ', e);
  }
  /**/
}

export const DetectMobile = () => {
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const mobile = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ),
  );
  window.mobile = mobile;
  return mobile;
};

export const getEstimate = (estimate) => {
  let sales_estimate = null;
  if (estimate) {
    // Estimate [123, 456, 'USD']
    if (estimate[0].length === 3) {
      const estimatePriceHigh = parseInt(estimate[0][0])
        .toLocaleString()
        .replaceAll('.', ',');
      const estimatePriceLow = parseInt(estimate[0][1])
        .toLocaleString()
        .replaceAll('.', ',');
      const currency = estimate[0][2];

      const sales_estimate_currency_str = convertCurrency(currency);
      if (estimatePriceHigh === estimatePriceLow) {
        sales_estimate = `Est. ${sales_estimate_currency_str}${estimatePriceHigh}`;
      } else {
        sales_estimate = `Est. ${sales_estimate_currency_str}${estimatePriceHigh}-${estimatePriceLow}`;
      }
    }

    // Estimate [123, 'USD']
    if (estimate[0].length === 2) {
      const estimatePrice = parseInt(estimate[0][0])
        .toLocaleString()
        .replaceAll('.', ',');
      const currency = estimate[0][1];

      const sales_estimate_currency_str = convertCurrency(currency);
      sales_estimate = `Est. ${sales_estimate_currency_str}${estimatePrice}`;
    }
  }
  return sales_estimate;
};

export const getEstimateCurrency = (estimate) => {
  let sales_estimate_currency = undefined;

  // Estimate [123, 456, 'USD']
  if (estimate && estimate[0].length === 3)
    sales_estimate_currency = estimate[0][2];

  // Estimate [123, 'USD']
  if (estimate && estimate[0].length === 2)
    sales_estimate_currency = estimate[0][1];

  return sales_estimate_currency;
};

export const getPrice = (sales_prices, sales_estimate_currency) => {
  let sales_prices_str = null;
  if (sales_prices && sales_prices.length > 0 && Array.isArray(sales_prices)) {
    // Check if any estimate matches any sales currency
    // NOTE: there are usually 3 or more sales prices and never more than one estimate
    const itemSalesPricesWithEstimate = sales_prices?.filter(
      (item) => item?.[1] === sales_estimate_currency,
    );
    const foundSalesPriceWithEstimate = itemSalesPricesWithEstimate.length > 0;

    // (1) If estimate, then take the figure with same currency as estimate
    // (2) If no estimate, then take figure ending with 00
    // (3) If no estimate and two figures ending with 00, take “500”
    // (4) If no estimate and no figure ending with 00, take the one with 0
    // (5) if 2 x 0 on two different currencies, take the one ending “50"
    // (6) if none above applies, use USD ($) as default currency

    // Check if any price ends with 00
    const itemSalesPricesWith00Ending = sales_prices.filter(
      (item) => item[0].toString().slice(-2) === '00',
    );
    const notFoundSalesPriceWith00Ending =
      itemSalesPricesWith00Ending.length === 0;
    const foundSalesPriceWith00EndingSingle =
      itemSalesPricesWith00Ending.length === 1;
    const foundSalesPriceWith00EndingMultiple =
      itemSalesPricesWith00Ending.length > 1;

    // Check if any price ends with 500
    const itemSalesPricesWith500Ending = sales_prices.filter(
      (item) => item[0].toString().slice(-2) === '500',
    );
    const foundSalesPriceWith500Ending =
      itemSalesPricesWith500Ending.length > 0;

    // Check if any price ends with 0
    const itemSalesPricesWith0Ending = sales_prices.filter(
      (item) => item[0].toString().slice(-1) === '0',
    );
    const foundSalesPriceWith0EndingSingle =
      itemSalesPricesWith0Ending.length === 1;
    const foundSalesPriceWith0EndingMultiple =
      itemSalesPricesWith0Ending.length > 1;

    // Check if any price ends with 500
    const itemSalesPricesWith50Ending = sales_prices.filter(
      (item) => item[0].toString().slice(-2) === '50',
    );
    const foundSalesPriceWith50Ending = itemSalesPricesWith00Ending.length > 0;

    // Check if any price ends with 500
    const itemSalesPricesUSD = sales_prices.filter((item) => item[1] === 'USD');
    const foundSalesPricesUSD = itemSalesPricesUSD.length > 0;

    // Determine sales price
    let salesPricesItem = '';
    if (foundSalesPriceWithEstimate) {
      salesPricesItem = itemSalesPricesWithEstimate[0];
    } else if (foundSalesPriceWith00EndingSingle) {
      salesPricesItem = itemSalesPricesWith00Ending[0];
    } else if (
      foundSalesPriceWith00EndingMultiple &&
      foundSalesPriceWith500Ending
    ) {
      salesPricesItem = itemSalesPricesWith500Ending[0];
    } else if (
      notFoundSalesPriceWith00Ending &&
      foundSalesPriceWith0EndingSingle
    ) {
      salesPricesItem = itemSalesPricesWith0Ending[0];
    } else if (
      notFoundSalesPriceWith00Ending &&
      foundSalesPriceWith0EndingMultiple &&
      foundSalesPriceWith50Ending
    ) {
      salesPricesItem = itemSalesPricesWith50Ending[0];
    } else if (foundSalesPricesUSD) {
      salesPricesItem = itemSalesPricesUSD[0];
    } else {
      salesPricesItem = sales_prices[0];
    }

    // Generate price item
    const price = parseInt(salesPricesItem[0])
      .toLocaleString()
      .replaceAll('.', ',');
    const currency = salesPricesItem[1];
    const sales_currency = convertCurrency(currency);
    sales_prices_str = ` ${sales_currency}${price}`;
  }
  return sales_prices_str;
};
