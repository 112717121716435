import React from 'react';
import styled from 'styled-components';

import Close from './Close';
import FadeIn from './FadeIn';
import ModalEmail from './ModalEmail';
import ModalName from './ModalName';
import ModalPassword from './ModalPassword';

import { useAppState } from '../hooks/useAppContext';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const Mask = styled.div`
  background: rgba(0, 0, 0, 0.75);
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
`;

const Inner = styled.div`
  max-width: 600px;
  padding: 1rem;
  width: 100%;
  z-index: 999;
`;

const Wrapper = styled.div`
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
`;

const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`;

const AlignRightInner = styled.div`
  position: absolute;
`;

const Modal = () => {
  const { closeModal, modal } = useAppState();
  const visible = modal && modal !== 'Default';
  return (
    visible && (
      <FadeIn zIndex="3">
        <Container>
          <Mask onClick={() => closeModal()} />
          <Inner>
            <Wrapper>
              <AlignRight>
                <AlignRightInner>
                  <Close onClick={() => closeModal()} />
                </AlignRightInner>
              </AlignRight>
              {modal === 'ModalEmail' && <ModalEmail />}
              {modal === 'ModalName' && <ModalName />}
              {modal === 'ModalPassword' && <ModalPassword />}
            </Wrapper>
          </Inner>
        </Container>
      </FadeIn>
    )
  );
};
export default Modal;
