import styled from 'styled-components';

const HeroTitle = styled.div`
  border-bottom: 1px solid #d0d0d0;
  font-family: serif;
  font-size: 5rem;
  line-height: 1.4;
  margin: 0 0 3rem;
  padding: 3rem 0;
  text-align: center;
  width: 100%;
  word-break: break-all;

  @media (max-width: 992px) {
    font-size: 2rem;
  }
`;

export default HeroTitle;
