import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Link = styled(NavLink)`
  color: #212529;
  padding: 0 1rem;
  text-transform: uppercase;

  &:hover {
    color: #212529;
    text-decoration: none;
  }
`;

export default Link;
