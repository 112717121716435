import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Button from '../components/Button';
import FadeIn from '../components/FadeIn';
import HeroTitle from '../components/HeroTitle';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import Page from '../components/Page';
import PageContainer from '../components/PageContainer';

import { useAppState } from '../hooks/useAppContext';

const Grid = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? 'row' : 'column')};
  justify-content: space-between;
  padding-bottom: 5rem;
  width: 100%;
`;

const ColumnDesktop = css`
  justify-content: flex-end;
  text-align: right;
  margin: 0;
  width: auto;
`;

const Column = styled.div`
  display: flex;
  flex: ${(props) => (props.flex ? 1 : 0)};
  margin: 0 0 2rem;
  width: 100%;
  ${(props) => props.isDesktop && ColumnDesktop}
`;

const columnContainerStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ColumnContainer = styled.div`
  ${(props) => !props.isDesktop && columnContainerStyles}
`;

const ColumnLarge = styled.div`
  flex: 1;
  padding: 0 6rem;
`;

const LinkDisabled = css`
  color: #a1a1a1;
  text-decoration: line-through;
  pointer-events: none;
`;

const Link = styled.div`
  color: #007bff;
  cursor: pointer;
  margin: ${(props) => (props.isDesktop ? '0' : '0 0 1rem')};
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  ${(props) => props.disabled && LinkDisabled}
`;

const Title = styled.p`
  font-weight: bold;
`;

const StyledButtonDisabled = css`
  cursor: not-allowed;
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
  ${(props) => props.disabled && StyledButtonDisabled}
`;

const Settings = () => {
  const {
    billingLink,
    billingLinkState,
    fetchBillingLink,
    handleProfitwell,
    isDesktop,
    isLoggedIn,
    logout,
    media,
    openBillingLink,
    profile,
    profileState,
    query,
    setModal,
  } = useAppState();

  // Handle loading state
  const [loading, setLoading] = useState();
  useEffect(() => {
    const loaders = [profileState?.loading, billingLinkState?.loading];
    setLoading(loaders.some(Boolean));
  }, [billingLinkState, profileState]);

  // Fetch billing link
  useEffect(() => {
    fetchBillingLink();
    handleProfitwell(profile, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      {loading ? (
        <Loader />
      ) : (
        <FadeIn>
          <Page>
            <HeroTitle>{profile.name}</HeroTitle>
            <Grid {...media}>
              <Column flex>
                <ColumnContainer {...media}>
                  <StyledButton
                    onClick={() => openBillingLink()}
                    disabled={!isLoggedIn || !billingLink}
                  >
                    View Billing Details
                  </StyledButton>
                  <StyledButton onClick={() => logout()}>Logout</StyledButton>
                </ColumnContainer>
                {isDesktop && profile.email && (
                  <ColumnLarge>
                    <Title {...media}>Account Details</Title>
                    <>
                      <p>
                        <span style={{ color: '#a1a1a1' }}>Email:</span>
                        <br />
                        <span>{profile.email}</span>
                      </p>
                      <p>
                        <span style={{ color: '#a1a1a1' }}>Password:</span>
                        <br />
                        <span>*******************</span>
                      </p>
                    </>
                  </ColumnLarge>
                )}
              </Column>
              <Column style={{ minWidth: 200 }} {...media}>
                <div>
                  <Title {...media}>Account Management</Title>
                  <Link
                    {...media}
                    onClick={() => setModal('ModalEmail')}
                    disabled={!isLoggedIn}
                  >
                    Change email
                  </Link>
                  <Link
                    {...media}
                    onClick={() => setModal('ModalPassword')}
                    disabled={!isLoggedIn}
                  >
                    Change password
                  </Link>
                  <Link
                    {...media}
                    onClick={() => setModal('ModalName')}
                    disabled={!isLoggedIn}
                  >
                    {profile.name ? 'Change name' : 'Add name'}
                  </Link>
                  <br />
                  <Link
                    {...media}
                    onClick={() => openBillingLink()}
                    disabled={!isLoggedIn || !billingLink}
                  >
                    Manage payment info
                  </Link>
                  <Link
                    {...media}
                    onClick={() => openBillingLink()}
                    disabled={!isLoggedIn || !billingLink}
                  >
                    Billing details
                  </Link>
                  <br />
                  <Link
                    {...media}
                    onClick={() => openBillingLink()}
                    disabled={!isLoggedIn || !billingLink}
                    style={{ color: 'red' }}
                  >
                    Cancel Membership
                  </Link>
                </div>
              </Column>
            </Grid>
          </Page>
        </FadeIn>
      )}
      <Modal />
    </PageContainer>
  );
};

export default Settings;
