import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  padding: 1rem;
  max-width: 800px;
  width: 100%;
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  padding: 2rem;
  text-align: center;
`;

const Button = styled.button`
  background: transparent;
  border: 1px solid black;
  border-radius: 30px;
  color: #000;
  padding: 1rem 2rem;
  font-weight: bold;

  &:hover {
    background: black;
    color: #fff;
  }
`;

const Spacer = styled.div`
  margin-top: 1rem;
`;

const NotFound = () => (
  <Container>
    <Inner>
      <h3>Oops, the page you are looking for does not exist!</h3>
      <Spacer>
        <Button onClick={(e) => (window.location = '/')}>
          Start a new search
        </Button>
      </Spacer>
    </Inner>
  </Container>
);

export default NotFound;
