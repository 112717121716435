import styled from 'styled-components';
import { MENU_HEIGHT, MENU_HEIGHT_MOBILE } from '../utils/constants';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: ${MENU_HEIGHT}px;

  @media (max-width: 767px) {
    padding-top: ${MENU_HEIGHT_MOBILE}px;
  }
`;

export default PageContainer;
