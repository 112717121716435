import { createStore } from 'redux';

export const SET_SHOW_HEADER = 'SET_SHOW_HEADER';

export function setShowHeader(payload) {
  return { type: SET_SHOW_HEADER, payload };
}

const initialState = {
  showHeader: true,
};

export function rootReducer(state = initialState, action) {
  if (action.type === SET_SHOW_HEADER) {
    return { ...state, showHeader: action.payload.showHeader };
  }
  return state;
}

export const store = createStore(rootReducer);
export default store;
