import React, { createContext, useContext } from 'react';

import useArtwork from './useArtwork';
import useBilling from './useBilling';
import useDragAndDrop from './useDragAndDrop';
import useMenu from './useMenu';
import useModal from './useModal';
import useProfile from './useProfile';
import useProfitwell from './useProfitwell';
import useQuery from './useQuery';
import useSearch from './useSearch';
import useSearchFilters from './useSearchFilters';
import useSearchInput from './useSearchInput';
import useUpdateProfile from './useUpdateProfile';
import useWindowDimensions from './useWindowDimensions';

export const AppContext = createContext({});
export const useAppState = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const artwork = useArtwork();
  const billing = useBilling();
  const dragAndDrop = useDragAndDrop();
  const menu = useMenu();
  const modal = useModal();
  const profile = useProfile(true);
  const profitWell = useProfitwell();
  const query = useQuery();
  const search = useSearch();
  const searchFilters = useSearchFilters();
  const searchInput = useSearchInput();
  const updateProfile = useUpdateProfile();
  const windowDimensions = useWindowDimensions();
  const context = {
    ...artwork,
    ...billing,
    ...dragAndDrop,
    ...menu,
    ...modal,
    ...profile,
    ...profitWell,
    ...search,
    ...searchFilters,
    ...searchInput,
    ...updateProfile,
    ...windowDimensions,
    query,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
