import React from 'react';

const SvgCamera = (props) => (
  <svg
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.144 1.28h2.862" stroke="#000" strokeLinecap="square" />
    <path stroke="#000" strokeWidth={1.2} d="M1.6 3.136h21.411V15.9H1.6z" />
    <ellipse
      cx={12.306}
      cy={9.658}
      rx={4.723}
      ry={4.608}
      stroke="#000"
      strokeWidth={1.2}
    />
    <ellipse cx={20.749} cy={5.608} rx={0.286} ry={0.279} fill="#000" />
  </svg>
);

export default SvgCamera;
