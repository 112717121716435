import React, { useRef } from 'react';
import styled from 'styled-components';

import BigUploadButton from './BigUploadButton';
import FadeIn from './FadeIn';

import { useAppState } from '../hooks/useAppContext';
import useWindowDimensions from '../hooks/useWindowDimensions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.windowHeight}px;
`;

const Mask = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  left: 0;
  height: 100%;
  justify-content: center;
  padding: 87px 0 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
`;

const DragAndDropContainer = ({ children }) => {
  const {
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    setSearchResultsMap,
  } = useAppState();
  const window = useWindowDimensions();
  const inputRef = useRef(null);

  const handleDragAndDrop = (e) => {
    setSearchResultsMap();
    handleDrop(e, inputRef);
  };

  return (
    <Container
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={(e) => handleDragAndDrop(e)}
      windowHeight={window.height}
    >
      {children}
      {isDragging && (
        <FadeIn zIndex="999">
          <Mask
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDragAndDrop(e)}
          >
            <BigUploadButton ref={inputRef} />
          </Mask>
        </FadeIn>
      )}
    </Container>
  );
};

export default DragAndDropContainer;
