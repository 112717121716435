import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

import api from '../utils/api';
import { getSearchEndpoint } from '../utils/search';
import { searchTypes } from '../constants/search';

function useSearchInput() {
  const location = useLocation();
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchByTitleInputRef = useRef(null);
  const initialState = { data: null, error: null, loading: null };
  const [searchInputVisible, setSearchInputVisible] = useState(false);
  const [searchInputResults, setSearchInputResults] = useState();
  const [searchInputResultsState, setSearchInputResultsState] =
    useState(initialState);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchType, setSearchType] = useState(searchTypes.ARTIST);
  const [searchByTitleInputValid, setSearchByTitleInputValid] = useState();

  useEffect(() => {
    document.addEventListener('click', handleClick, false);
    handleSearchInputAfterNavigate();
    return () => {
      document.removeEventListener('click', handleClick, false);
      setSearchInputVisible();
      setSearchInputResults();
      setSearchInputResultsState(initialState);
      setSearchInputValue();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClick = (e) => {
    if (dropdownRef?.current?.contains(e?.target)) {
      return;
    }
    setSearchInputVisible(false);
  };

  const setSearchByType = (type) => {
    setSearchType(type);
    if (searchInputRef?.current?.value) {
      searchInputRef.current.value = '';
    }
  };

  const fetchSearchResults = async (query) => {
    setSearchInputResultsState({ ...initialState, loading: true });
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const searchEndppint = getSearchEndpoint(searchType);
      const response = await api(
        `${baseUrl}/api/aw_lots/${searchEndppint}?q=${query}`,
      );
      const data = response?.results;
      setSearchInputResultsState({ ...initialState, data });
      setSearchInputResults(data);
    } catch (error) {
      setSearchInputResultsState({ ...initialState, error });
      console.log(error);
    }
  };

  const changeHandler = async (e) => {
    if (searchType !== searchTypes.ARTIST) return;
    let delay;
    const stopRequest = () => clearTimeout(delay);
    const handleRequest = () => {
      stopRequest();
      delay = setTimeout(() => {
        setSearchInputValue(e.target.value);
        fetchSearchResults(e.target.value);
        if (!searchInputVisible) {
          setSearchInputVisible(true);
        }
      }, 500);
    };
    handleRequest();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchInputChange = debounce(changeHandler, 500);

  const handleSearchInputSelect = (result) => {
    if (searchInputRef?.current) {
      searchInputRef.current.value = result?.name;
    }

    setSearchInputValue('');
    setSearchInputVisible(false);
    setSearchInputResults();
    setSearchInputResultsState(initialState);
  };

  const handleSearchInputFocus = () => {
    // if (searchInputRef?.current?.value) {
    //   searchInputRef.current.value = '';
    // }
  };

  const handleSearchByTitleInputChange = () => {
    if (
      searchByTitleInputRef?.current?.value?.length > 0 &&
      searchByTitleInputRef?.current?.value?.length < 4
    ) {
      setSearchByTitleInputValid(false);
      return;
    }
    setSearchByTitleInputValid(true);
  };

  const handleSearchByTitleInputClear = () => {
    if (searchByTitleInputRef?.current?.value) {
      searchByTitleInputRef.current.value = '';
    }
  };

  const handleSearchInputAfterNavigate = () => {
    const pathname = location.pathname;
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const artist = params?.artist;
    const collection = params?.collection;
    const title = params?.title;
    if (pathname === '/search' && title) {
      searchByTitleInputRef.current.value = title;
    }
    if (pathname === '/search' && artist) {
      searchInputRef.current.value = artist;
      return;
    }
    if (pathname === '/search' && collection) {
      searchInputRef.current.value = collection;
      return;
    }
    if (pathname === '/search' && !artist) {
      searchInputRef.current.value = '';
      return;
    }
    if (pathname !== '/search') {
      searchInputRef.current.value = '';
      return;
    }
  };

  const submitSearchForm = (e, setSearchState, searchState) => {
    e.preventDefault();
    if (!searchInputRef.current.value) {
      return;
    }
    if (searchType === searchTypes.COLLECTION) {
      const collection = searchInputRef.current.value;
      setSearchState({
        ...searchState,
        collection,
      });
    }
  };

  const submitSearchByTitleForm = (e, setSearchState, searchState) => {
    e.preventDefault();
    if (!searchByTitleInputRef.current.value) {
      setSearchState({ ...searchState, title: undefined });
      return;
    }
    const title = searchByTitleInputRef.current.value;
    setSearchState({
      ...searchState,
      title,
    });
  };

  return {
    dropdownRef,
    handleSearchInputChange,
    searchByTitleInputRef,
    searchByTitleInputValid,
    searchInputRef,
    searchInputResults,
    searchInputResultsState,
    searchInputValue,
    searchInputVisible,
    searchType,
    handleSearchByTitleInputChange,
    handleSearchByTitleInputClear,
    handleSearchInputFocus,
    handleSearchInputSelect,
    setSearchByTitleInputValid,
    setSearchInputResults,
    setSearchInputResultsState,
    setSearchInputVisible,
    setSearchByType,
    submitSearchByTitleForm,
    submitSearchForm,
  };
}

export default useSearchInput;
