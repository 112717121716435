import React, { useState } from 'react';
import styled from 'styled-components';

import FadeIn from './FadeIn';

import { useAppState } from '../hooks/useAppContext';

const Inner = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 1rem;
  border: 1px solid #aaa;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  padding: 2rem;
  position: fixed;
  right: 1rem;
  z-index: 999;
`;

const Toggle = styled.div`
  background: #fff;
  border-radius: 1rem;
  border: 1px solid #aaa;
  bottom: 1rem;
  cursor: pointer;
  padding: 1rem;
  position: fixed;
  right: 1rem;
  z-index: 10;
`;

const DebugModal = () => {
  const { searchState } = useAppState();
  const [visible, setVisible] = useState(false);
  return (
    process.env.NODE_ENV === 'development' && (
      <>
        <Toggle onClick={() => setVisible(!visible)}>Debug</Toggle>
        {visible && (
          <FadeIn zIndex="3">
            <Inner>
              <pre>{JSON.stringify(searchState, null, 2)}</pre>
            </Inner>
          </FadeIn>
        )}
      </>
    )
  );
};
export default DebugModal;
