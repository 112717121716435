import React from 'react';

import NotFound from '../components/NotFound';
import Page from '../components/Page';
import PageContainer from '../components/PageContainer';

const Settings = () => (
  <PageContainer>
    <Page>
      <NotFound />
    </Page>
  </PageContainer>
);

export default Settings;
