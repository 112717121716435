import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import Anonymous from './Anonymous';
import Card from './Card';
import BigUploadButton from './BigUploadButton';
import SimpleButton from './SimpleButton';
import FadeIn from './FadeIn';
import Loader from './Loader';
import SearchResultsModal from './SearchResultsModal';

import useInfiniteScroll from '../hooks/useInfiniteScroll';
import { useAppState } from '../hooks/useAppContext';

const Container = styled.div`
  align-items: center;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  width: 100%;
  flex: 1;
`;

const Wrapper = styled.div`
  align-items: flex-start;
  color: #444444;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1140px;
  padding: 1rem;
  width: 100%;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

const NoResults = styled.div`
  font-size: 1.4rem;
  padding: 2rem 0;
`;

const Footer = styled.div`
  background: #fff;
  border-top: 1px solid #d0d0d0;
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const SearchResults = () => {
  const {
    artwork,
    fetchArtworkResults,
    handleCardClick,
    handleLoadMore,
    handleUploadClick,
    imagePreview,
    imageZoom,
    isLoggedIn,
    isPaid,
    page,
    pageSize,
    previousRoute,
    profileState,
    resetSearchState,
    searchContainerRef,
    searchResultsMap,
    searchResultsState,
    searchState,
    setImagePreview,
    uploadInputRef,
    uploadResultsMap,
    uploadResultsState,
  } = useAppState();
  const { id } = useParams();

  useEffect(() => {
    if (previousRoute !== '/search' && id && artwork) {
      fetchArtworkResults(id, artwork);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artwork]);

  useEffect(() => {
    if (searchState) {
      // window.scrollTo(0, 0);
      setImagePreview(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  useEffect(() => {
    resetSearchState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResultsMap]);

  useInfiniteScroll(handleLoadMore, searchState);

  return (
    <>
      {profileState.loading && <Loader />}
      {!profileState.loading && (
        <>
          {profileState.data === null && (!isLoggedIn || !isPaid) ? (
            <Anonymous isLoggedIn={isLoggedIn} paid={isPaid} />
          ) : (
            <>
              <FadeIn flex>
                <Container>
                  {uploadResultsState?.loading && <Loader />}
                  {imagePreview && !searchState && (
                    <Wrapper>
                      {!uploadResultsState?.loading &&
                        uploadResultsMap?.length > 0 && (
                          <CardWrapper>
                            {uploadResultsMap?.map((result, index) => (
                              <Card
                                result={result}
                                key={`${result.pk}-${index}`}
                                onCardClick={(e) =>
                                  handleCardClick(e, result, true)
                                }
                              />
                            ))}
                          </CardWrapper>
                        )}

                      {!uploadResultsState?.loading &&
                        uploadResultsMap?.length < 1 && (
                          <>
                            <h2>No artwork was found</h2>
                            <NoResults>
                              Please try again by cropping the image or
                              alternatively use the textual search.
                            </NoResults>
                          </>
                        )}
                    </Wrapper>
                  )}
                  {!imagePreview && searchState && (
                    <Wrapper
                      ref={searchContainerRef}
                      // style={{ minHeight: '100vh' }}
                    >
                      {searchResultsMap?.length > 0 && (
                        <CardWrapper>
                          {searchResultsMap?.map((result, index) => (
                            <Card
                              result={result}
                              key={`${result.pk}-${index}`}
                              onCardClick={(e) => handleCardClick(e, result)}
                            />
                          ))}
                        </CardWrapper>
                      )}

                      {page === 1 && searchResultsState?.loading && <Loader />}

                      {!searchResultsState?.loading &&
                        searchResultsMap?.length < 1 && (
                          <NoResults>
                            <h2>No results were found.</h2>
                          </NoResults>
                        )}
                    </Wrapper>
                  )}
                  {!imagePreview && !searchState && (
                    <Container center>
                      <BigUploadButton
                        ref={uploadInputRef}
                        onClick={() => handleUploadClick(uploadInputRef)}
                      />
                    </Container>
                  )}
                </Container>
              </FadeIn>

              {!id &&
                searchState &&
                searchResultsMap?.length > 0 &&
                !imagePreview && (
                  <Footer>
                    <SimpleButton
                      auto
                      secondary
                      onClick={() => handleLoadMore()}
                      loading={searchResultsState?.loading}
                      disabled={searchResultsMap?.length < page * pageSize}
                    >
                      Load more
                    </SimpleButton>
                  </Footer>
                )}
            </>
          )}
        </>
      )}
      <SearchResultsModal imageZoom={imageZoom} />
    </>
  );
};

export default SearchResults;
