export const processSvg = (img, svg) => {
  return new Promise((resolve) => {
    const xml = new XMLSerializer().serializeToString(svg);
    const svg64 = btoa(xml);
    const b64Start = 'data:image/svg+xml;base64,';
    const image64 = b64Start + svg64;
    img.src = image64;
    img.onload = () => resolve(img);
  });
};

export const getFileFromSvg = async (imageElement) => {
  const div = document.createElement('div');
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  div.innerHTML = imageElement;
  const svg = div.querySelector('svg');
  const image = await processSvg(img, svg);
  canvas.getContext('2d').drawImage(img, 0, 0);
  const file = image.src;
  return file;
};

export const processBlobImage = (img) => {
  return new Promise((resolve) => {
    img.src = img;
    img.onload = () => resolve(img);
  });
};

export const getFileFromBlob = async (imageElement) => {
  const div = document.createElement('div');
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  div.innerHTML = imageElement;
  const image = await processBlobImage(img);
  canvas.getContext('2d').drawImage(image, 0, 0);
  const file = image.src;
  return file;
};

export const dataUrlToFile = async (dataUrl, type) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], 'image', { type: type || blob.type });
};
