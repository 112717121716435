import { ArtworkField } from './ArtworkField';
import { ARTWORK_TYPE, getSource } from '../../shared/utils';

export const ArtworkDefault = ({ artwork }) => {
  const source = getSource(artwork?.source_type);
  return (
    <>
      <div style={{ fontSize: '36px' }}>{artwork?.artist}</div>
      {artwork?.title && artwork?.year_of_work_verbose && (
        <div>
          {artwork?.title}, {artwork?.year_of_work_verbose}
        </div>
      )}
      <div className="mt-3">{artwork?.materials}</div>
      <div>{artwork?.size}</div>

      {artwork?.catalogue_number && <div>Nr. {artwork?.catalogue_number}</div>}
      <div className="mt-3 font-weight-bold">
        <div style={{ fontFamily: 'ArtWishListMedium', fontSize: '36px' }}>
          {artwork?.sales_estimate}
        </div>

        <div>{artwork?.sales_estimate}</div>
        <div>{artwork?.sales_house}</div>
        <div>{artwork?.date}</div>
      </div>

      <div>{artwork?.collection}</div>

      <div className="mt-3 text-justify" style={{ fontSize: '12px' }}>
        <ArtworkField field={artwork?.lot_number} label="Lot Number" />
        <ArtworkField field={artwork?.info} label="Sale" />
        <ArtworkField field={artwork?.inscriptions} label="Inscriptions" />
        <ArtworkField field={artwork?.description} label="Description" />
        <ArtworkField field={artwork?.edition} label="Inscriptions" />
        <ArtworkField field={artwork?.misc} label="Miscellaneous" />
        <ArtworkField field={artwork?.publisher} label="Publisher" />
        <ArtworkField field={artwork?.provenance} label="Provenance" />
        <ArtworkField field={artwork?.literature} label="Literature" />
      </div>

      {source === ARTWORK_TYPE.AUCTION && (
        <ArtworkField field={artwork?.url} label="URL" />
      )}
    </>
  );
};
