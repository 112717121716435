import { useState, useLayoutEffect } from 'react';

const useInfiniteScroll = (fn, trigger) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useLayoutEffect(() => {
    setIsMounted(true);
    setIsFetching(false);
    window.addEventListener('scroll', handleScroll);
    return () => {
      setIsMounted(false);
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useLayoutEffect(() => {
    const asyncCallback = async () => {
      await fn();
    };
    if (!isFetching || !isMounted) {
      return;
    }
    asyncCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleScroll = () => {
    const isAtBottomOfScreen =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;
    setIsFetching(isAtBottomOfScreen);
  };

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
