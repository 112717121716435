import styled, { css } from 'styled-components';
import Loader from './Loader';

const Button = styled.div`
  align-items: center;
  background: #000;
  border-radius: 2rem;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.75rem 2rem;
  position: relative;
  text-align: center;
  width: 100%;
  ${(props) => props.auto && ButtonAuto}
  ${(props) => props.disabled && ButtonDisabled}
  ${(props) => props.inline && ButtonInline}
  ${(props) => props.loading && ButtonLoading}
  ${(props) => props.secondary && ButtonSecondary}
`;

const ButtonAuto = css`
  margin: 0;
  width: auto;
`;

const ButtonDisabled = css`
  opacity: 0.2;
  pointer-events: none;
`;

const ButtonInline = css`
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  text-align: left;
  width: auto;
`;

const ButtonLoading = css``;

const ButtonSecondary = css`
  background: #fff;
  border: 1px solid #d0d0d0;
  color: #000;
  margin-right: 1rem;
`;

const SimpleButton = ({ children, loading, ...props }) => {
  return <Button {...props}>{loading ? <Loader small /> : children}</Button>;
};

export default SimpleButton;
