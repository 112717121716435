import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import Burger from './Burger';
import Link from './Link';
import LinkButton from './LinkButton';
import SearchBar from './SearchBar';
import SvgLogo from './SvgLogo';
import UploadButton from './UploadButton';

import { useAppState } from '../hooks/useAppContext';
import { MENU_HEIGHT, MENU_HEIGHT_MOBILE } from '../utils/constants';

const Container = styled.div`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${MENU_HEIGHT}px;
  justify-content: space-between;
  max-width: 1140px;
  padding: 0 1rem;
  width: 100%;

  ${(props) =>
    props.isMobile &&
    css`
      height: ${MENU_HEIGHT_MOBILE}px;
    `}
`;

const MiddleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 30px;
`;

const MenuContainer = styled.div`
  cursor: pointer;
  padding: 1rem;
`;

const MobileMenu = styled.div`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 1rem;
  position: fixed;
  top: ${MENU_HEIGHT}px;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.5s cubic-bezier(0.65, 0.05, 0.36, 1),
    opacity 0.1s linear 0.5s;
  width: 100%;
  z-index: 2;
  opacity: 0;
  ${(props) => props.open && MobileMenuOpen}

  ${(props) =>
    props.isMobile &&
    css`
      top: ${MENU_HEIGHT_MOBILE}px;
    `}
`;

const MobileMenuOpen = css`
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s cubic-bezier(0.65, 0.05, 0.36, 1),
    opacity 0.1s linear;
`;

const StyledLink = styled(Link)`
  text-transform: uppercase;
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};

  &:hover {
    color: #007bff;
  }
`;

const HeaderMask = styled.div`
  background #fff;
  height: 200px;
  left: 0;
  position: fixed;
  top: -200px;
  width: 100%;
  z-index: 100;
`;

const Header = () => {
  const {
    handleMenuOpen,
    isDesktop,
    isLoggedIn,
    isMobile,
    isTablet,
    menuOpen,
    media,
  } = useAppState();
  return (
    <>
      <HeaderMask />
      <Container>
        <Wrapper {...media}>
          <NavLink to="/search">
            <SvgLogo />
          </NavLink>
          {isDesktop && (
            <>
              <MiddleContainer>
                <UploadButton />
                <SearchBar />
              </MiddleContainer>
              <StyledLink to="/settings" $visible={isLoggedIn}>
                My Account
              </StyledLink>
            </>
          )}
          {isTablet && (
            <>
              <MiddleContainer>
                <SearchBar />
              </MiddleContainer>
              <MenuContainer onClick={handleMenuOpen}>
                <Burger open={menuOpen} />
              </MenuContainer>
            </>
          )}
          {isMobile && (
            <>
              <MenuContainer onClick={handleMenuOpen}>
                <Burger open={menuOpen} />
              </MenuContainer>
            </>
          )}
        </Wrapper>
      </Container>
      {!isDesktop && (
        <MobileMenu open={menuOpen} {...media}>
          <LinkButton to="/settings">My Account</LinkButton>
          <UploadButton fullWidth />
          {isMobile && <SearchBar fullWidth />}
        </MobileMenu>
      )}
    </>
  );
};

export default Header;
