import { ArtworkField } from './ArtworkField';

export const ArtworkAuction = ({ artwork }) => {
  let price = 'No price information';
  if (artwork?.bought_in) {
    price = 'Bought In';
  } else if (artwork?.sales_prices) {
    price = artwork?.sales_prices;
  }
  return (
    <div style={{ fontSize: '13px' }}>
      <div className="mt-3" style={{ fontSize: '24px' }}>
        {' '}
        {artwork?.artist}{' '}
      </div>
      <div className="mt-1 text-justify" style={{ fontSize: '18px' }}>
        {artwork?.full_title}
      </div>
      <div className="mt-3">
        <div>{artwork?.materials}</div>
        <div>{artwork?.size}</div>
        <div>{artwork?.duration}</div>
      </div>

      <div className="mt-3" style={{ fontFamily: 'ArtWishListMedium' }}>
        <div style={{ fontSize: '30px' }}>{price}</div>

        <div style={{ lineHeight: '14px' }}>
          <div>
            {artwork?.sales_estimate
              ? artwork?.sales_estimate
              : 'No estimate available'}
          </div>
          <div>
            {artwork?.house
              ? artwork?.house
              : 'No auction house information available'}
          </div>
          <div>
            {artwork?.date ? artwork?.date : 'No date information available'}
          </div>
        </div>
      </div>

      <div className="mt-3 text-justify">
        <ArtworkField field={artwork?.lot_number} label="Lot Number" />
        <ArtworkField field={artwork?.info} label="Sale" />

        <ArtworkField field={artwork?.description} label="Description" />
        <ArtworkField field={artwork?.inscriptions} label="Incriptions" />
        <ArtworkField field={artwork?.misc} label="Miscellaneous" />

        <ArtworkField
          field={artwork?.year_of_casting}
          label="Year Of Casting"
        />
        <ArtworkField field={artwork?.foundry} label="Foundry" />
        <ArtworkField field={artwork?.edition} label="Edition" />
        <ArtworkField field={artwork?.printer} label="Printer" />
        <ArtworkField field={artwork?.publisher} label="Publisher" />
        <ArtworkField field={artwork?.rights} label="Rights" />

        <ArtworkField field={artwork?.collection} label="Collection" />
        <ArtworkField field={artwork?.location} label="Location" />
        <ArtworkField field={artwork?.acquisition} label="Acquisition Method" />
        <ArtworkField
          field={artwork?.inventory_number}
          label="Inventory Number"
        />
        <ArtworkField
          field={artwork?.catalogue_number}
          label="Catalogue Number"
        />

        <ArtworkField field={artwork?.provenance} label="Provenance" />
        <ArtworkField
          field={artwork?.exhibition_history}
          label="Exhibition History"
        />
        <ArtworkField field={artwork?.literature} label="Literature" />
      </div>
    </div>
  );
};
