import React, { forwardRef } from 'react';
import styled from 'styled-components';

import SvgDragAndDrop from './SvgDragAndDrop';
import { useAppState } from '../hooks/useAppContext';

const Wrapper = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 100%;
  color: #aeaeae;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 380px;
  justify-content: center;
  padding-top: 3rem;
  transition: all 0.1s ease;
  transform: scale(0.9);
  width: 380px;

  &:hover {
    background: #aeaeae;
    color: #fff;
  }
`;

const Text = styled.div`
  padding: 2rem 0;
  text-align: center;
`;

const StyledSvgUpload = styled(SvgDragAndDrop)`
  fill: currentColor;
  transition: all 0.1s ease;
`;

const BigUploadButton = forwardRef((props, ref) => {
  const { handleImagePreview } = useAppState();
  return (
    <Wrapper {...props}>
      <StyledSvgUpload />
      <Text>
        Drag and drop or <br />
        click to upload an image
      </Text>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={ref}
        onChange={() => handleImagePreview(ref)}
        accept="image/*"
      />
    </Wrapper>
  );
});

export default BigUploadButton;
