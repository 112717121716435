import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import SvgChevron from './SvgChevron';

const Container = styled.div`
  margin-bottom: 1rem;
`;

const Chevron = styled(SvgChevron)`
  height: 12px;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 0.4s all;
  width: 12px;

  ${(props) =>
    !props.open &&
    css`
      transform: rotate(-90deg);
    `}
`;

const Header = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const Label = styled.div`
  color: #000;
  font-size: 1.125rem;
`;

const Inner = styled.div`
  overflow: hidden;
  transition: 0.4s all;
`;

export default function Accordion({ children, label, isOpen }) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  return (
    <Container>
      <Header onClick={() => setOpen(!open)}>
        <Label>{label}</Label>
        <Chevron open={open} />
      </Header>
      <Inner
        ref={ref}
        style={
          open
            ? { height: `${ref?.current?.scrollHeight ?? 0}px`, opacity: 1 }
            : { height: '0px', opacity: 0 }
        }
      >
        {children}
      </Inner>
    </Container>
  );
}
