import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Input from './Input';

import { useAppState } from '../hooks/useAppContext';

const Container = styled.div`
  text-align: center;
`;

const Field = styled.div`
  padding: 0 0 0.5rem;

  &:last-child {
    padding: 0;
  }
`;

const ModalName = () => {
  const {
    profile,
    name: { loading, valid },
    onNameChange,
    updateName,
    fetchProfile,
    closeModal,
  } = useAppState();
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateName();
      await fetchProfile();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <form onSubmit={handleUpdate}>
        <Field>
          <h5>Change name</h5>
          <p style={{ color: '#adadad' }}>
            Update the name associated with this account
          </p>
        </Field>
        <Field>
          <Input
            placeholder={profile.name}
            onChange={(e) => onNameChange(e.target.value)}
            valid={valid}
          />
        </Field>
        <Field>
          <Button onClick={handleUpdate} disabled={!valid} loading={loading}>
            Update
          </Button>
        </Field>
      </form>
    </Container>
  );
};
export default ModalName;
