import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import SvgClose from './SvgClose';
import ArtworkModal from './ArtworkModal';

import { useAppState } from '../hooks/useAppContext';
import {
  MENU_HEIGHT,
  MENU_HEIGHT_MOBILE,
  SEARCH_FILTERS_TOP_OFFSET,
} from '../utils/constants';

const ModalWrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: calc(100% - ${MENU_HEIGHT + 1}px);
  justify-content: center;
  position: fixed;
  transform: translateY(1px);
  width: 100%;
  z-index: 2;

  ${(props) =>
    props.isMobile &&
    `
      height: calc(100% - ${MENU_HEIGHT_MOBILE + 1}px);
  `}
`;

const ModalInner = styled.div`
  background: white;
  height: 100%;
  overflow: scroll;
  position: fixed;
  width: 100%;
  z-index: 100;

  ${(props) =>
    !props.isDesktop &&
    `
      padding-top: 3rem;
  `}
`;

const ImageElement = styled.img`
  position: fixed;
`;

const CloseButton = styled(SvgClose)`
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
`;

const Mask = styled.div`
  background: #fff;
  height: calc(100% - ${MENU_HEIGHT + 1}px);
  position: fixed;
  transform: translateY(1px);
  width: 100%;
  z-index: 1;
`;

const loadImage = async (path) => {
  if (!path) return;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
  });
};

const SearchResultsModal = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    artworkRef,
    fetchArtwork,
    imageZoom,
    media,
    previousRoute,
    searchContainerRef,
    searchResultsMap,
    setImageZoom,
    setIsFiltersVisible,
  } = useAppState();
  const ref = useRef(null);
  const modalInnerRef = useRef(null);
  const maskRef = useRef(null);
  const [currentId, setCurrentId] = useState();

  useEffect(() => {
    const searchContainerRefVar = searchContainerRef?.current;
    return () => {
      if (searchContainerRefVar) {
        searchContainerRefVar.style = '';
      }
      document.body.style = '';
      clearAllBodyScrollLocks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const init = async (id) => {
      setImageZoomBounds(id);
      setCurrentId(id);
      await fetchArtwork(id);
      await loadImage(imageZoom?.result?.path);
      if (previousRoute !== '/search') {
        await new Promise((res) => setTimeout(() => res(), 200));
        setElementScrollPosition();
      }
      await new Promise((res) => setTimeout(() => res(), 200));
      await zoomToImage(id);
      window.addEventListener('keydown', closeArtwork);
    };
    if (id) {
      init(id);
    } else {
      zoomFromImage();
      window.removeEventListener('keydown', closeArtwork);
    }

    return () => {
      window.removeEventListener('keydown', closeArtwork);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setImageZoomBounds(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultsMap]);

  const setElementScrollPosition = () => {
    const searchResultsImage = searchContainerRef?.current?.querySelector(
      `div[id="${id || currentId}"]`,
    );
    const offset = searchResultsImage?.offsetTop || 0;
    window.scrollTo(0, offset);
  };

  const setImageZoomBounds = (id) => {
    const { result, searchBounds, searchImageRef } = getBounds(id);
    setImageZoom({
      result,
      bounds: searchBounds,
      target: searchImageRef,
    });
  };

  const getBounds = (id) => {
    const searchResultsImage = searchContainerRef?.current?.querySelector(
      `div[id="${id || currentId}"] img`,
    );
    const searchBounds = searchResultsImage?.getBoundingClientRect() || {};
    if (!id) searchBounds.y = searchBounds?.y + 20;
    const artworkImage = artworkRef?.current?.querySelector('img');
    const artworkBounds = artworkImage?.getBoundingClientRect();
    const result = searchResultsMap?.find(
      (item) => item?.pk === +id || item?.pk === +currentId,
    );
    return {
      artworkBounds,
      artworkImageRef: artworkImage,
      result,
      searchBounds,
      searchImageRef: searchResultsImage,
    };
  };

  const zoomToImage = async (id) => {
    gsap.killTweensOf(artworkRef?.current);
    gsap.killTweensOf(ref?.current);
    gsap.killTweensOf(searchContainerRef?.current);

    const timeline = gsap.timeline({
      defaults: { duration: 0.3 },
      onStart: () => setIsFiltersVisible(false),
    });

    if (searchContainerRef?.current) {
      timeline.to(
        searchContainerRef?.current,
        { autoAlpha: 0, y: -20 },
        'zoomInAnimation',
      );
    }

    if (ref?.current) {
      const { artworkBounds, searchBounds } = getBounds(id);
      timeline.fromTo(
        ref?.current,
        {
          left: searchBounds?.x,
          top: searchBounds?.y,
          height: searchBounds?.height,
          width: searchBounds?.width,
        },
        {
          left: artworkBounds?.x,
          top: artworkBounds?.y,
          height: artworkBounds?.height,
          width: artworkBounds?.width,
          ease: 'power3.inOut',
          duration: 0.4,
          onComplete: async () => {
            if (modalInnerRef?.current) {
              disableBodyScroll(modalInnerRef?.current);
            }
          },
        },
        'zoomInAnimation',
      );
    }

    if (artworkRef?.current) {
      timeline.to(
        artworkRef?.current,
        { autoAlpha: 1, duration: 0.4 },
        'zoomInAnimation+=0.5',
      );
    }

    return true;
  };

  const zoomFromImage = async () => {
    gsap.killTweensOf(artworkRef?.current);
    gsap.killTweensOf(ref?.current);
    gsap.killTweensOf(searchContainerRef?.current);

    if (maskRef?.current) {
      gsap.to(maskRef?.current, { autoAlpha: 0 });
    }

    // Enable scrolling and scroll to element
    const artworkModal = modalInnerRef?.current;
    if (artworkModal) {
      enableBodyScroll(artworkModal);
      setElementScrollPosition();
    }

    const { artworkBounds, artworkImageRef, searchBounds } = getBounds();

    setImageZoom({
      ...imageZoom,
      bounds: artworkBounds,
      target: artworkImageRef,
    });

    const timeline = gsap.timeline({
      defaults: { duration: 0.3 },
      onComplete: () => {
        const scroll = -document.body.getBoundingClientRect().top;
        if (scroll < SEARCH_FILTERS_TOP_OFFSET) {
          setIsFiltersVisible(true);
        }
      },
    });

    if (artworkRef?.current) {
      timeline.to(
        artworkRef?.current,
        { autoAlpha: 0, duration: 0.2 },
        'zoomOutAnimation',
      );
    }

    if (searchContainerRef?.current) {
      timeline.to(
        searchContainerRef?.current,
        { autoAlpha: 1, y: 0 },
        'zoomOutAnimation',
      );
    }

    if (ref?.current) {
      timeline.to(
        ref?.current,
        {
          left: searchBounds?.x,
          top: searchBounds?.y,
          height: searchBounds?.height,
          width: searchBounds?.width,
          ease: 'power3.inOut',
          duration: 0.4,
          onComplete: () => setCurrentId(),
        },
        'zoomOutAnimation',
      );
    }

    return true;
  };

  const closeArtwork = (event) => {
    if (event?.key === 'Escape') {
      navigate('/search');
    }
  };

  return (
    <>
      <Mask ref={maskRef} />
      {currentId ? (
        <>
          {imageZoom?.result?.path && (
            <ImageElement
              src={imageZoom?.result?.path}
              style={{
                left: imageZoom?.bounds?.x,
                top: imageZoom?.bounds?.y,
                height: imageZoom?.bounds?.height,
                width: imageZoom?.bounds?.width,
              }}
              ref={ref}
            />
          )}
          <ModalWrapper style={{ opacity: 0 }} ref={artworkRef} {...media}>
            <ModalInner ref={modalInnerRef} {...media}>
              <CloseButton onClick={() => navigate('/search')} />
              <ArtworkModal />
            </ModalInner>
          </ModalWrapper>
        </>
      ) : null}
    </>
  );
};

export default SearchResultsModal;
