import { useEffect, useState } from 'react';
import api from '../utils/api';

function useUpdateProfile() {
  const initialState = {
    data: null,
    error: null,
    loading: null,
    value: null,
    valid: null,
  };
  const [email, setEmail] = useState(initialState);
  const [name, setName] = useState(initialState);
  const [password, setPassword] = useState(initialState);
  const [passwordConfirm, setPasswordConfirm] = useState(initialState);

  useEffect(() => {
    setPasswordConfirm({ valid: null });
  }, [password]);

  const onEmailChange = (value) => {
    const validateEmail = (string) => {
      const re = /\S+@\S+\.\S+/;
      if (!string) return null;
      return re.test(string);
    };

    const valid = validateEmail(value);
    setEmail({ valid, value });
  };

  const onNameChange = (value) => {
    const validateName = (string) => {
      const re =
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
      if (!string) return null;
      return re.test(string);
    };

    const valid = validateName(value);
    setName({ valid, value });
  };

  const onPasswordChange = (value) => {
    const validatePassword = (string) => {
      // Min 1 uppercase letter
      // Min 1 lowercase letter
      // Min 1 special character
      // Min 1 number
      // Min 8 characters
      // Max 30 characters
      const re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
      if (!string) return null;
      return re.test(string);
    };

    const valid = validatePassword(value);
    setPassword({ valid, value });
  };

  const onPasswordConfirmChange = (valueConfirm, value) => {
    const validatePasswordConfirm = () => {
      const match = value === valueConfirm;
      if (!value || !valueConfirm) {
        return null;
      }
      return match;
    };
    const valid = validatePasswordConfirm();
    setPasswordConfirm({ valid, value: valueConfirm });
  };

  const updateEmail = async () => {
    setEmail({ ...email, loading: true });
    try {
      const response = await api(
        'https://api.facture.art/login/profile',
        {
          method: 'PUT',
        },
        {
          email: email.value,
        },
      );
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setEmail({ ...email, loading: false });
    }
  };

  const updateName = async () => {
    setName({ ...name, loading: true });
    try {
      const response = await api(
        'https://api.facture.art/login/profile',
        {
          method: 'PUT',
        },
        {
          name: name.value,
        },
      );
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setName({ ...name, loading: false });
    }
  };

  const updatePassword = async () => {
    setPassword({ ...password, loading: true });
    try {
      const response = await api(
        'https://api.facture.art/login/profile',
        {
          method: 'PUT',
        },
        {
          password: password.value,
        },
      );
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setPassword({ ...password, loading: false });
    }
  };

  return {
    email,
    name,
    password,
    passwordConfirm,
    onEmailChange,
    onNameChange,
    onPasswordChange,
    onPasswordConfirmChange,
    updateEmail,
    updateName,
    updatePassword,
  };
}

export default useUpdateProfile;
