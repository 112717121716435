export default function useProfitwell() {
  const handleProfitwell = (query, profile) => {
    if (process.env.NODE_ENV !== 'development' && query?.token) {
      window.profitwell('start', { user_email: profile.email });
    }
  };

  return {
    handleProfitwell,
  };
}
