import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import api from '../utils/api';
import { mapImageToResults } from '../shared/mapping';
import { disableScrolling, enableScrolling } from '../shared/scrolling';
import { getSearchEndpoint } from '../utils/search';

function useSearch() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const searchContainerRef = useRef(null);
  const searchInputRef = useRef(null);
  const artworkRef = useRef(null);
  const initialState = { data: null, error: null, loading: null };
  const [searchState, setSearchState] = useState();
  const [searchResults, setSearchResults] = useState();
  const [searchResultsMap, setSearchResultsMap] = useState();
  const [searchResultsState, setSearchResultsState] = useState(initialState);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(24);
  const [previousPageSize] = useState(6);
  const [previousPageSizeOffset] = useState(30);
  const [isFetchingSearchResults, setIsFetching] = useState(false);
  const [sourceType, setSourceType] = useState();
  const [medium, setMedium] = useState();
  const [salePriceMin, setSalePriceMin] = useState();
  const [salePriceMax, setSalePriceMax] = useState();
  const [saleYearMin, setSaleYearMin] = useState();
  const [saleYearMax, setSaleYearMax] = useState();
  const [yearOfWorkMin, setYearOfWorkMin] = useState();
  const [yearOfWorkMax, setYearOfWorkMax] = useState();
  const [heightMin, setHeightMin] = useState();
  const [heightMax, setHeightMax] = useState();
  const [widthMin, setWidthMin] = useState();
  const [widthMax, setWidthMax] = useState();
  const [previousRoute, setPreviousRoute] = useState();
  const [imageZoom, setImageZoom] = useState();

  useEffect(() => {
    reset();
    setSourceType(searchState?.source_type);
    fetchSearchResults(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  // Search results
  useEffect(() => {
    const response = searchResultsState?.data;
    setSearchResultsMap(mapImageToResults(response));
  }, [searchResultsState.data]);

  const reset = () => {
    setPage(1);
    setSearchResults();
    setSearchResultsMap();
    setSearchResultsState(initialState);
  };

  const handleSearchSelect = async (result, handleSearchInputSelect) => {
    await navigate('/search');
    setSearchState({
      artist: result?.name,
      artist_pk: result?.pk,
    });
    handleSearchInputSelect(result);
  };

  const handleLoadMore = async () => {
    const results = searchResultsMap?.length;
    const offset = page * pageSize;
    const fullPage = results === offset;

    const artworkSize = searchState?.size;
    const artworkOffset = (page - 1) * pageSize;
    const fullArtworkPage =
      results === previousPageSizeOffset + artworkSize ||
      results === previousPageSizeOffset + artworkSize + artworkOffset;

    if (fullPage || fullArtworkPage) {
      setPage(page + 1);
      await fetchSearchResults(false, true);
      setIsFetching(false);
    }
  };

  const fetchSearchResults = async (freshRequest) => {
    if (['/search', '/artwork'].every((value) => !pathname.includes(value))) {
      return;
    }
    if (freshRequest) {
      disableScrolling();
      setPage(1);
    }
    setSearchResultsState({ ...searchResultsState, loading: true });
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const searchParams = {
        size: pageSize,
        offset: freshRequest ? 0 : page * pageSize,
      };
      let searchEndpoint = getSearchEndpoint();
      if (freshRequest && searchState?.offset) {
        searchParams.offset = searchState?.offset;
      }
      if (!freshRequest && !searchState?.offset) {
        const resultsLength = searchResultsMap?.length;
        if (page === 1) {
          searchParams.offset = resultsLength;
        } else {
          const artworkOffset = (page - 1) * pageSize;
          const offset = previousPageSizeOffset + previousPageSize;
          searchParams.offset = offset + artworkOffset;
        }
      }
      if (freshRequest && searchState?.size) {
        searchParams.size = searchState?.size;
      }
      if (!freshRequest && searchState?.size) {
        searchParams.size = pageSize;
      }
      if (searchState?.artwork) {
        searchParams.artwork = searchState?.artwork;
      }
      if (searchState?.artist) {
        searchParams.q = searchState?.artist;
      }
      if (searchState?.collection) {
        searchEndpoint = getSearchEndpoint('collection');
        searchParams.q = searchState?.collection;
        delete searchState.artist_pk;
      }
      if (searchState?.title) {
        searchParams.text_title = searchState?.title;
      }
      if (searchState?.artist_pk) {
        searchParams.artist_pk = searchState?.artist_pk;
      }
      if (searchState?.source_type) {
        searchParams.source_category = searchState?.source_type;
      }
      if (searchState?.medium) {
        searchParams.medium = searchState?.medium;
      }
      if (searchState?.sort_by) {
        searchParams.sort_by = searchState?.sort_by;
      }
      if (searchState?.sale_price_usd_max) {
        searchParams.sale_price_usd_max = searchState?.sale_price_usd_max;
      }
      if (searchState?.sale_price_usd_min) {
        searchParams.sale_price_usd_min = searchState?.sale_price_usd_min;
      }
      if (searchState?.sale_year_max) {
        searchParams.sale_year_max = searchState?.sale_year_max;
      }
      if (searchState?.sale_year_min) {
        searchParams.sale_year_min = searchState?.sale_year_min;
      }
      if (searchState?.year_of_work_max) {
        searchParams.year_of_work_max = searchState?.year_of_work_max;
      }
      if (searchState?.year_of_work_min) {
        searchParams.year_of_work_min = searchState?.year_of_work_min;
      }
      if (searchState?.sizes_mm_min) {
        searchParams.sizes_mm_min = searchState?.sizes_mm_min;
      }
      if (searchState?.sizes_mm_max) {
        searchParams.sizes_mm_max = searchState?.sizes_mm_max;
      }
      const params = new URLSearchParams(searchParams);
      if (!params.has('q') && !params.has('artwork')) {
        return;
      }
      const response = await api(
        `${baseUrl}/api/aw_lots/${searchEndpoint}?${params.toString()}`,
      );
      const oldResults = searchResultsState?.data?.results || [];
      const newResults = response?.results || [];
      let data;
      if (freshRequest) {
        data = response;
      } else {
        data = {
          ...response,
          results: [...oldResults, ...newResults],
        };
      }
      setSearchResultsState({
        ...searchResultsState,
        data,
        loading: false,
      });
      setSearchResults(data);
    } catch (error) {
      setSearchResultsState({
        ...initialState,
        data: [],
        error,
      });
      console.log(error);
    } finally {
      if (freshRequest) {
        setTimeout(() => {
          enableScrolling();
        }, 200);
      }
    }
  };

  const handleApplyFilters = (
    filterSourceType,
    handleSearchByTitleInputClear,
    sortBy,
  ) => {
    setPage(1);
    const searchParams = {};

    // Reset artwork
    // searchParams.artwork = undefined;

    // Sale price
    if (salePriceMax) {
      searchParams.sale_price_usd_max = salePriceMax;
    }
    if (salePriceMin) {
      searchParams.sale_price_usd_min = salePriceMin;
    }

    // Sale year
    if (saleYearMax) {
      searchParams.sale_year_max = saleYearMax;
    }
    if (saleYearMin) {
      searchParams.sale_year_min = saleYearMin;
    }

    // Year of work
    if (yearOfWorkMax) {
      searchParams.year_of_work_max = yearOfWorkMax;
    }
    if (yearOfWorkMin) {
      searchParams.year_of_work_min = yearOfWorkMin;
    }

    // Height
    if (heightMin || widthMin) {
      searchParams.sizes_mm_min = `${widthMin ? widthMin * 100 : 0},${
        heightMin ? heightMin * 100 : 0
      },0`;
    }
    if (heightMax || widthMax) {
      searchParams.sizes_mm_max = `${widthMax ? widthMax * 100 : 0},${
        heightMax ? heightMax * 100 : 0
      },0`;
    }

    // Sort by
    if (sortBy) {
      searchParams.sort_by = sortBy;
    }
    if (sortBy === 'Default') {
      searchParams.sort_by = undefined;
    }

    // Medium
    if (medium) {
      searchParams.medium = medium;
    }

    // Filter source type
    if (filterSourceType) {
      searchParams.source_type = filterSourceType;
    }
    if (filterSourceType === 'All') {
      setSalePriceMin('');
      setSalePriceMax('');
      setSourceType('');
      setMedium('');
      setSalePriceMin('');
      setSalePriceMax('');
      setSaleYearMin('');
      setSaleYearMax('');
      setHeightMin('');
      setHeightMax('');
      setWidthMin('');
      setWidthMax('');
      setYearOfWorkMin('');
      setYearOfWorkMax('');
      searchParams.source_type = undefined;
      searchParams.medium = undefined;
      searchParams.sort_by = undefined;
      searchParams.title = undefined;
      searchParams.sale_price_usd_max = undefined;
      searchParams.sale_price_usd_min = undefined;
      searchParams.sale_year_max = undefined;
      searchParams.sale_year_min = undefined;
      searchParams.year_of_work_max = undefined;
      searchParams.year_of_work_min = undefined;
      searchParams.sizes_mm_min = undefined;
      searchParams.sizes_mm_max = undefined;
      handleSearchByTitleInputClear();
    }
    if (!filterSourceType) {
      searchParams.source_type = undefined;
      searchParams.sort_by = undefined;
      searchParams.title = undefined;
    }

    // Clear sort_by when source_type changes
    if (searchParams?.source_type !== 1 || searchState?.source_type !== 1) {
      searchParams.sort_by = undefined;
    }

    // Set search state if keys exist
    const state = { ...searchState, ...searchParams };

    // Delete artwork from search state to enable filtering
    if (pathname === '/search' && state.artwork) {
      delete state.artwork;
      delete state.offset;
      delete state.size;
    }

    setSearchState(Object.keys(state).length ? state : undefined);
  };

  const fetchArtworkResults = (id, artwork) => {
    if (id) {
      // TODO: Update API with multiple of 3 and 4 (36)
      setSearchState({
        artwork: id,
        offset: 0,
        size: 6,
        artist: artwork?.artist,
        artist_pk: artwork?.artist_pk,
      });
    }
  };

  const handleCardClick = (e, result, imagePreview) => {
    const id = result?.pk;
    const target = e.target?.querySelector('img') || e.target;
    const bounds = target?.getBoundingClientRect();
    setImageZoom({ target, result, bounds });
    setPreviousRoute(pathname);

    if (id && imagePreview) {
      window.open(`/artwork/${id}`, '_blank');
      return;
    }

    if (id) {
      navigate(`/artwork/${id}`);
    }
  };

  const searchStateHasKeys = useMemo(
    () =>
      searchState &&
      Object.keys(searchState)
        .map((key) => searchState[key])
        .every(Boolean),
    [searchState],
  );

  const resetSearchState = () => {
    setSearchState();
    setSearchResultsState(initialState);
  };

  return {
    artworkRef,
    fetchArtworkResults,
    fetchSearchResults,
    handleApplyFilters,
    handleCardClick,
    handleLoadMore,
    handleSearchSelect,
    heightMax,
    heightMin,
    imageZoom,
    isFetchingSearchResults,
    medium,
    page,
    pageSize,
    previousRoute,
    reset,
    resetSearchState,
    salePriceMax,
    salePriceMin,
    saleYearMax,
    saleYearMin,
    searchContainerRef,
    searchInputRef,
    searchResults,
    searchResultsMap,
    searchResultsState,
    searchState,
    searchStateHasKeys,
    setHeightMax,
    setHeightMin,
    setImageZoom,
    setPageSize,
    setSalePriceMax,
    setSalePriceMin,
    setSaleYearMax,
    setSaleYearMin,
    setSearchResults,
    setSearchResultsMap,
    setSearchResultsState,
    setSearchState,
    setSourceType,
    setWidthMax,
    setWidthMin,
    setYearOfWorkMax,
    setYearOfWorkMin,
    sourceType,
    widthMax,
    widthMin,
    yearOfWorkMax,
    yearOfWorkMin,
    setMedium,
  };
}

export default useSearch;
