import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Input from './Input';

import { useAppState } from '../hooks/useAppContext';

const Container = styled.div`
  text-align: center;
`;

const Field = styled.div`
  padding: 0 0 0.5rem;

  &:last-child {
    padding: 0;
  }
`;

const ModalEmail = () => {
  const {
    profile,
    email: { loading, valid },
    onEmailChange,
    updateEmail,
    fetchProfile,
    closeModal,
  } = useAppState();
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateEmail();
      await fetchProfile();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <form onSubmit={handleUpdate}>
        <Field>
          <h5>Change email address</h5>
          <p style={{ color: '#adadad' }}>
            Update the email address associated with this account
          </p>
        </Field>
        <Field>
          <Input
            placeholder={profile.email}
            onChange={(e) => onEmailChange(e.target.value)}
            valid={valid}
            type="email"
          />
        </Field>
        <Field>
          <Button onClick={handleUpdate} disabled={!valid} loading={loading}>
            Update
          </Button>
        </Field>
      </form>
    </Container>
  );
};
export default ModalEmail;
