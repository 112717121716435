import React from 'react';

import DebugModal from '../components/DebugModal';
import ImagePreview from '../components/ImagePreview';
import PageContainer from '../components/PageContainer';
import SearchResults from '../components/SearchResults';
import SearchFilters from '../components/SearchFilters';

const Search = () => (
  <PageContainer>
    <ImagePreview />
    <SearchFilters />
    <SearchResults />
    <DebugModal />
  </PageContainer>
);

export default Search;
